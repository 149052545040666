import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ecopoxy-base',
  templateUrl: './ecopoxy-base.component.html',
  styleUrls: ['./ecopoxy-base.component.scss']
})
export class EcopoxyBaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openPdfResina(){
    window.open("../../../../assets/pdf/Catalogo.pdf", '_blank');
  }

}

