import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'woods-carousel-galeria',
  templateUrl: './woods-carousel-galeria.component.html',
  styleUrls: ['./woods-carousel-galeria.component.scss']
})
export class WoodsCarouselGaleriaComponent implements OnInit {

  ImgURL = "../../../assets/img/carousel/img01.jpeg"

  constructor() { }

  ngOnInit(): void {

  }


  setIMG(entrada:string){

    this.ImgURL = entrada;
  }

}
