

<div class="contenedorVideo text-center">


    <div class="">   
        <video class="woods-video-class woods-video-container" autoplay [muted]="'muted'" loop >
            <source src="../../../../assets/video/galeria/home.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
    

</div>