<div class="woods-contenedor-promocion">

<woods-navbar></woods-navbar>

<div class="productContainer mt-5">

    <div *ngIf="cargando">
        <div class="text-center mt-5">
            <woods-loading></woods-loading>
            <h3 class="mt-3">Cargando...</h3>
        </div>
    </div>

    <div *ngIf="!cargando">
        <h1 class="animate-charcter">Información del producto</h1>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
    
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">

                        <div class="woods-contenedor-wally-productos text-rigth" *ngIf="Producto.wally === 1">
                            <div  class="contenerdor-pos-wally">
                                <woods-wally></woods-wally>
                            </div>
                            
                        </div>

                        <img [src]="'../../../assets/img/productos/foto/' + imgSeleccionada +'.jpg'"  class="custom-product-img">
                        
                    </div>

                    <div class="col-12 mt-3">
                        <div class="row">
                            <div class="col-3" *ngIf="Producto.Foto !== ''" >
                                <img [src]="'../../../assets/img/productos/foto/' + Producto.Foto +'.jpg'"  class="custom-product-img-selector " (click)="clickImg('1')" [ngClass]="{'imgSellecionada': seleccionado1}">
                                
                            </div>
                            <div class="col-3"  *ngIf="Producto.Foto2 !== ''">
                                <img [src]="'../../../assets/img/productos/foto/' + Producto.Foto2 +'.jpg'"  class="custom-product-img-selector" (click)="clickImg('2')" [ngClass]="{'imgSellecionada': seleccionado2}">

                            </div>
                            <div class="col-3"  *ngIf="Producto.Foto3 !== ''">
                                <img [src]="'../../../assets/img/productos/foto/' + Producto.Foto3 +'.jpg'"  class="custom-product-img-selector" (click)="clickImg('3')" [ngClass]="{'imgSellecionada': seleccionado3}">

                            </div>
                            <div class="col-3"  *ngIf="Producto.Foto4 !== ''">
                                <img [src]="'../../../assets/img/productos/foto/' + Producto.Foto4 +'.jpg'"  class="custom-product-img-selector" (click)="clickImg('4')" [ngClass]="{'imgSellecionada': seleccionado4}">

                            </div>

                        </div>
                    </div>
                </div>
               
            </div>
    
            <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <h3 class="animate-charcter-2"> {{ Producto.Nombre }} </h3>

                <h4 class="text-light">CRC <span class="woods-precio-viejo">{{ Producto.Precio }}</span> </h4>
                <h4 class="text-light"><span class="">{{ CalculoDescuento(Producto.Precio) | currency:'CRC ' }}</span> </h4>
                <br>
                <small class="text-light">*Precio sin IVA</small>

                <div class="mt-3 text-light">
                    <ul>
                        <li>
                            <strong>Descripción:</strong> <p style="white-space: pre-line">{{ Producto.Descripcion }} </p>
                        </li>
    
                        <li>
                            <strong>Cantidad Disponible: </strong>{{ Producto.Disponible }} 
                        </li>
    
                        <li>
                            <strong>SKU: </strong>{{ Producto.SKU }} 
                        </li>
                    </ul>
                </div>

                <br>
                <label>
                    <span class="mr-2 text-light">Para adquirir esta pieza contáctenos por</span>
                    <a href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        <i class="fa fa-whatsapp fa-2x woods-whatsapp-icon" aria-hidden="true"></i>
                    </a>
                </label>
                <br>

                
    
                
                
    
            </div>
    
        </div>
    
    </div>

    

</div>

<woods-footer></woods-footer>

</div>