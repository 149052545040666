import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'woods-wally',
  templateUrl: './woods-wally.component.html',
  styleUrls: ['./woods-wally.component.scss']
})
export class WoodsWallyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
