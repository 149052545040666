import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import{ RestApiService } from "./services/rest-api.service"


@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {

  IdCategoria :any;
  DataCategoria: any;

  ListaDeProductos:any =[];

  cargando = true;

  constructor(public route: ActivatedRoute, private restApi:RestApiService) { 
    window.scrollTo(0,0);

    route.params.subscribe(parametro =>{
      this.cargando =true;
      this.IdCategoria = parametro['id'];

      this.restApi.obtenerCategoria(this.IdCategoria ).subscribe((res:any)=>{

        this.DataCategoria = res[0];

        
        this.restApi.obtenerProductos(res[0]["Nombre"]).subscribe((resProductos:any)=>{

     
          this.ListaDeProductos = resProductos;
          
      
          this.cargando =false;
        }); 

        

        //LLamado a retornar todos los productos

      });


    })


    this.ngOnInit()
   
  }

  ngOnInit(): void {
    
  }

}
