import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppSettings } from 'src/environments/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  url = "database/getproducto/?ID=";
  urlproductos = "database/getproductosbycategoria/?ID="


constructor(private http: HttpClient) { }

  getDatosProducto( id:string){
    return this.http.get(AppSettings.BASE_URL + this.url +  id);
  }

  obtenerProductos(id:string){
    return this.http.get(AppSettings.BASE_URL + this.urlproductos +  id);
  }

}
