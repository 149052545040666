<woods-navbar></woods-navbar>

<div class="mb-5">
    <div class="text-center mt-5">
        <img src="../../../assets/img/logos/WOODSCR GOLD.png" width="250" height="250" alt="Woods Costa Rica">
    </div>

    <div class="mr-5 ml-5 mt-5">
        <h3>Nosotros</h3>

        <hr>

        <h1>Exclusivas maderas con servicio personalizado de calidad.</h1>

        <p class="mt-5">
            Maderas del Reventazón fue fundada en 2013 con el objetivo de suministrar maderas de alta calidad y exclusivas, 
            secadas en horno, para la gran área metropolitana, así también como resinas epóxicas premium especiales para los 
            amantes de la madera. La empresa se centra en brindar un excelente servicio y asesoría, y ha logrado crecer gracias a 
            sus clientes y colaboradores. Maderas del Reventazón espera seguir facilitando madera única para la creación de proyectos 
            hermosos en el futuro.
        </p>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../../../assets/img/about/1.jpg" width="100%" height="100%" alt="Woods Costa Rica">
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 contenedorTextoAbout">
                
                <div class="text-center">
                    <h3>Compromiso con la calidad: seguimiento desde la obtención hasta la entrega de la madera</h3>
                </div>
                <p>
                    La empresa sigue el proceso de obtención y entrega de la madera a sus clientes para asegurarse de mantener 
                    su excelente calidad durante todo el trayecto, debido a la importancia que tiene este material para sus clientes.

                </p>
                
            </div>

        </div>

        <hr>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 contenedorTextoAbout">

                <div class="text-center">
                    <h3>Variedad de maderas</h3>
                </div>
                <p>
                    La empresa cuenta con una amplia variedad de maderas exclusivas que se adaptan a diferentes gustos y propósitos, 
                    en distintas dimensiones para proyectos grandes y pequeños.

                </p>

            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../../../assets/img/about/2.jpg" width="100%" height="100%" alt="Woods Costa Rica">
            </div>

        </div>

        <hr>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../../../assets/img/about/3.jpg" width="100%" height="100%" alt="Woods Costa Rica">
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 contenedorTextoAbout">
                <div class="text-center">
                    <h3>Cenízaro</h3>
                </div>
                <p>
                    La madera de cenízaro es de color café claro a marrón medio, con rayas más oscuras que contrastan con su color 
                    claro externo, y una densidad alta característica de la madera dura, lo que la hace resistente a los insectos y 
                    manchas. Su diseño de beta entrelazada ofrece mucho contraste, y puede ser utilizada tanto en proyectos exteriores 
                    como interiores debido a su alta durabilidad. Además, cada pieza tiene características únicas.
                </p>
            </div>

        </div>

        <hr>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 contenedorTextoAbout">
                <div class="text-center">
                    <h3>Nuestras Instalaciones</h3>
                </div>
                <p>
                    Las instalaciones de la empresa ofrecen una gran variedad de productos de madera con calidez y aroma, lo que la convierte 
                    en un lugar ideal para los amantes de la madera. Están ubicados en Curridabat, San José, Costa Rica.
                </p>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../../../assets/img/about/4.jpg" width="100%" height="100%" alt="Woods Costa Rica">
            </div>

        </div>

        <hr>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../../../assets/img/about/5.jpg" width="100%" height="100%" alt="Woods Costa Rica">
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 contenedorTextoAbout">
                <div class="text-center">
                    <h3>Exclusivas maderas con servicio personalizado de calidad</h3>
                </div>
                <p>
                    Brindamos un servicio altamente personalizado, ayudando a nuestro cliente a encontrar la mejor opción para realizar su proyecto.
                </p>
            </div>

        </div>

        <hr>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 contenedorTextoAbout">
                <div class="text-center">
                    <h3>Proyectos</h3>
                </div>
                <p>
                    Nos encantaría ver los proyectos que nuestros clientes han creado con nuestras maderas. Por favor, envíenos sus fotografías acompañadas de una nota al correo electrónico 
                    <a href="mailto:ventas@woodscostarica.com"><label>ventas@woodscostarica.com</label></a> para que podamos compartir sus trabajos.
                </p>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../../../assets/img/about/6.jpg" width="100%" height="100%" alt="Woods Costa Rica">
            </div>

        </div>

    </div>
</div>



<woods-footer></woods-footer>
