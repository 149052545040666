<woods-navbar></woods-navbar>

<div>
    <div class="text-center mt-5">
        <img src="../../../assets/img/logos/WOODSCR GOLD.png" width="250" height="250" alt="Woods Costa Rica">
    </div>

    <div class="ml-5 mr-5 mt-5">
        <h3 class="mt-5">POLÍTICA DE PRIVACIDAD, DE SEGURIDAD Y CONSENTIMIENTO INFORMADO</h3>

        <p class="mt-3">
            A través de la presente Política de Privacidad, de Seguridad y Consentimiento Informado, nosotros en 
            <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA (3-101-41590137)</strong>  , una empresa constituida y 
            vigente de conformidad con las Leyes de la República de Costa Rica, le informamos que contamos con una base 
            de datos en la cual recopilamos, procesamos y almacenamos su <strong>INFORMACION PERSONAL</strong> por medio de este
            <strong>SITIO WEB</strong>.
        </p>

        <ol type="I">
            <li>
                <strong>DEFINICIONES</strong>
                <ol class="mt-3">
                    <li>
                        <p>
                            <strong>CONSUMIDORES:</strong> Los consumidores finales de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> podrán 
                            adquirir los productos ofrecidos <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> través del <strong>SITIO WEB</strong>.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA:</strong> MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA, con cédula de persona 
                            jurídica número 3-101-41590137, con el correo electrónico <a href="mailto:info@woodscostarica.com"> <label>info@woodscostarica.com</label></a>  y 
                            con domicilio Freses, San José Province, Curridabat, 11801 y tambien 0, Palma De Mallorca, San José Province, San Rafael de Escazú, 10203.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>INFORMACION PERSONAL:</strong> Se entiende como “Información Personal”, el nombre del <strong>USUARIO</strong>  apellidos, correo 
                            electrónico, teléfono, domicilio exacto, dirección IP, información de geolocalización, información sobre compras y/o cualquier otra información personal requerida.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>SITIO WEB:</strong> El sitio web <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a>
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>USUARIO:</strong> Los <strong>USUARIOS REGISTRADOS</strong> y los <strong>USUARIOS VISITANTES</strong>podrán 
                            ser denominados en forma conjunta y para aquello en lo que les aplique a ambos por igual, como el “Usuario”.
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>USUARIO REGISTRADO:</strong> Todo usuario que se registre en el <strong>SITIO WEB</strong> de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>
                        </p>
                    </li>

                    <li>
                        <p>
                            <strong>USUARIO VISITANTE:</strong> Todo usuario que visite el <strong>SITIO WEB</strong> como visitante, pero que no se registre.
                        </p>
                    </li>
                </ol>
            </li>

            <li>
                <strong>INFORMADO DE ALMACENAMIENTO Y USO DE INFORMACIÓN</strong> 
                <p class="mt-3">
                    Al utilizar nuestro <strong>SITIO WEB</strong>, suscribir nuestro boletín, realizar compras en el <strong>SITIO WEB</strong>
                    o registrarse como <strong>USUARIO REGISTRADO</strong> en el <strong>SITIO WEB</strong>, o accesar como <strong>USUARIO VISITANTE</strong>  
                    a nuestro <strong>SITIO WEB</strong>, usted acepta libre y expresamente que su <strong> INFORMACIÓN PERSONAL</strong>, sea consultada, procesada, 
                    tratada y almacenada por <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>.
                </p>

                <p>
                    Salvo que específicamente se establezca lo contrario, todos los datos que <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>  le solicita en 
                    los procesos de registro y/o de compra en el <strong>SITIO WEB</strong>, son obligatorios. Los <strong>USUARIOS</strong> son quienes completan por 
                    sí mismos sus datos personales en el <strong>SITIO WEB</strong> de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> por lo que será su 
                    responsabilidad que la información sea real, veraz, completa y actualizada al momento del ingreso de la información en la base de datos vinculada 
                    con el <strong>SITIO WEB</strong> La omisión o inexactitud en alguno de ellos podría implicar la imposibilidad de que podamos prestarle los Servicios
                     solicitados y/o atender sus solicitudes y facultará a <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> para tomar acciones tales como pero no 
                     limitadas a: eliminar la cuenta del <strong>USUARIO</strong> no brindar los servicios requeridos, o que los datos suministrados por el <strong>USUARIO</strong> sean 
                     eliminados de la base de datos. 
                </p>

                <p>
                    Le rogamos que nos comunique inmediatamente cualquier modificación de sus datos a fin de que la información contenida en nuestra base de datos esté en todo momento 
                    actualizada y no contenga errores o inexactitudes. Lo anterior debe realizarse al siguiente correo electrónico: <a href="mailto:info@woodscostarica.com"> 
                    <label>info@woodscostarica.com</label></a>. Mientras no nos comunique lo contrario, entenderemos que sus datos no han sido modificados y que tenemos su 
                    consentimiento para utilizarlos.
                </p>

                <p>
                    De conformidad con la Ley de Protección de la Persona Frente al Tratamiento de sus Datos Personales de Costa Rica (en adelante la “Ley N° 8968”) y su Reglamento, 
                    WOODS COSTA RICAinforma a los Usuarios registrados de su Sitio, que los datos personales (<strong>INFORMACION PERSONAL</strong>) ecabados por 
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> en su <strong>SITIO WEB</strong> serán almacenados en un fichero automatizado bajo la responsabilidad de 
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>, con la finalidad de ejecutar los compromisos establecidos entre las partes de conformidad con lo 
                    indicado en los Términos y Condiciones del <strong>SITIO WEB</strong>.
                </p>

                <p>
                    Al aceptar nuestra Política de Privacidad y facilitar la Información de Usuario requerida, se entenderá que usted emite formal Consentimiento Informado, de 
                    acuerdo con la legislación costarricense. En este sentido, usted reconoce que: i) ha leído, entendido y acepta los alcances de esta Política de Privacidad, de
                     Seguridad y Consentimiento Informado, ii) que no tiene dudas relacionadas con la forma, medio y fin para el cual se le ha solicitado la información personal 
                     en el <strong>SITIO WEB</strong> , iii) acepta en forma expresa y voluntaria su registro en nuestra Base de Datos, iv) autoriza el tratamiento de la información 
                     otorgada según fue detallado y de acuerdo con los Productos y Servicios a los que se ha afiliado o registrado, y v) reconoce que fue informado de los medios 
                     por los cuales podrá ejercer los derechos de la Autodeterminación Informativa.
                </p>

                <p>
                    Salvo el Usuario nos indique por escrito lo contrario, estará otorgando su consentimiento expreso para que sus datos puedan ser utilizados por <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>
                    con la finalidad de remitirle información comercial (incluyendo, pero no limitado a noticias, ofertas y publicidad) sobre los Productos comercializados y/o las 
                    actividades y servicios ofrecidos por <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>. Estas comunicaciones podrán realizarse correo electrónico, 
                    SMS, o cualquier otro medio de comunicación.
                </p>

                <p>
                    En cualquier momento, el <strong>USUARIO</strong>  podrá ejercer el derecho de acceso, rectificación, cancelación y oposición, así como revocar su consentimiento 
                    para el tratamiento comercial de sus datos para lo cual deberá enviar un correo electrónico en este sentido a  <a href="mailto:info@woodscostarica.com"> 
                    <label>info@woodscostarica.com</label></a>.
                </p>

                <p>
                    <strong>II.1. Sobre la necesidad de compartir la INFORMACION PERSONAL para proveer los Servicios</strong>
                </p>

                <p>
                    Usted como <strong>USUARIO</strong> entiende y acepta en forma expresa, al registrar la <strong>INFORMACION PERSONAL </strong> en el <strong>SITIO WEB</strong>,
                    que dependiendo del Servicio que solicite a <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>  esta última deberá compartir en forma total o parcial su 
                    <strong>INFORMACION PERSONAL</strong> con terceros, para efectos de poder ejecutar el servicio requerido a través de nuestro <strong>SITIO WEB</strong>
                    (Ver una descripción detallada de los Servicios en los Términos y Condiciones de uso de nuestro <strong>SITIO WEB</strong>). 
                </p>

                <p>
                    Con base en lo anterior, el <strong>USUARIO</strong>  autoriza que dicha <strong>INFORMACION PERSONAL</strong>  sea compartida con terceros, tales como
                    pero no limitados a empresas de logística, procesadores de pagos, entidades financieras, u otros que deban participar en la ejecución de los servicios 
                    requeridos por el <strong>USUARIO</strong>  de conformidad con lo establecido en los Términos y Condiciones del <strong>SITIO WEB</strong>.
                </p>

                <p>
                    La transferencia de datos que se realice de conformidad con el presente consentimiento informado se realizará bajo estándares de seguridad que garanticen al 
                    <strong>USUARIO</strong> y al responsable de la base de datos que la información compartida en relación con el presente consentimiento informado será tratada, 
                    procesada y almacenada en concordancia con los fines específicos del presente consentimiento informado. 
                </p>
                <p>
                    <strong>II.2 Otras circunstancias bajo las cuales podría compartirse la Información</strong>
                </p>
                <p>
                    Además de lo indicado anteriormente, la <strong>INFORMACION PERSONAL</strong> podría ser compartida bajo las siguientes circunstancias:
                </p>

                <p>
                    II.2.1. En caso de que la <strong>INFORMACION PERSONAL</strong> deba ser divulgada por orden judicial o de alguna autoridad competente con facultades suficientes para requerirla.
                </p>

                <p>
                    II.2.2 En caso de proceso de debida diligencia y/o negociaciones y/o ejecución de o para una posible fusión, adquisición total o parcial, reestructuración o financiamiento a
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>.
                </p>

                <p>
                    II.2.3 En caso de que sea necesario compartir la <strong>INFORMACION PERSONAL</strong> con algún proveedor de servicios que subcontrate <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>.
                </p>

                <p>
                    II.2.4 Con terceros con quienes el <strong>USUARIO</strong> nos autorice compartir la <strong>INFORMACION PERSONAL</strong>.
                </p>

                <p>
                    II.2.5 En caso de que <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> deba denunciar a las autoridades competentes algún tipo de actividad ilegal por parte del 
                    <strong>USUARIO</strong> través de nuestro <strong>SITIO WEB</strong> o por medio de la utilización de los Servicios.
                </p>

                <p>
                    II.2.6 Con subsidiarias, afiliadas o miembros del mismo grupo de interés económico.
                </p>

                <p>
                    Al registrar la <strong>INFORMACION PERSONAL</strong>, como <strong>USUARIO</strong>  usted manifiesta entender los alcances de la autorización aquí otorgada y sus implicaciones 
                    y otorga su CONSENTIMIENTO LIBRE EXPRESO E INFORMADO para que <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> use la información recopilada para los fines aquí señalados.
                </p>

                <p>
                    Se reitera que en cualquier momento, el <strong>USUARIO</strong>  podrá ejercer el derecho de acceso, rectificación, cancelación y oposición, así como revocar su consentimiento 
                    para el tratamiento comercial de sus datos para lo cual deberá enviar un correo electrónico en este sentido a  <a href="mailto:info@woodscostarica.com"> 
                    <label>info@woodscostarica.com</label></a>. Asimismo, el USUARIO en todo momento podrá ejercer su derecho de portabilidad de datos, para lo cual deberá informar 
                    por escrito y al correo electrónico soporte@woodscostarica.com. sobre su interés en ejercer este derecho. 
                </p>

                <p>
                    El tratamiento, procesamiento y almacenamiento de datos personales por parte de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> se realizará por un término de 
                    diez años contados desde la fecha de cancelación de suscripción por parte del <strong>USUARIO</strong> en el <strong>SITIO WEB</strong>.
                </p>

            </li>

            <li>
                <strong>COMUNICACIÓN CON EL USUARIO</strong>

                <p class="mt-3">
                    Todo <strong>USUARIO</strong> que se registre y/o utilice los servicios del <strong>SITIO WEB</strong> y/o que nos escriba por correo electrónico, recibirá comunicaciones
                    por esa misma vía (el correo electrónico registrado por el <strong>USUARIO</strong>) por parte de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>
                </p>

                <p>
                    El <strong>USUARIO</strong> acepta que <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> podrá comunicar por medio del correo electrónico 
                    registrado por el <strong>USUARIO</strong>  información sobre promociones, sobre productos, invitaciones a eventos, notificaciones sobre cambios 
                    a los Términos y Condiciones, así como cualquier otra información o notificación formal que <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>
                    necesite comunicar al <strong>USUARIO</strong>
                </p>

                <p>
                    El <strong>USUARIO</strong> autoriza la recepción de correos electrónicos por parte de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>. 
                    Sin embargo, podrá, en cualquier momento, solicitar la suspensión de envío de correos a su cuenta de correo, enviando una solicitud en este sentido 
                    a  <a href="mailto:info@woodscostarica.com"><label>info@woodscostarica.com</label></a>. No obstante lo anterior, esto podrá ser motivo para cancelar 
                    los Servicios al <strong>USUARIO</strong> , ya que para la correcta utilización del <strong>SITIO WEB</strong>, es fundamental para <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> 
                    el poder comunicarse con el <strong>USUARIO</strong> vía correo electrónico.
                </p>

            </li>

            <li>
                <strong>NUESTRO SITIO WEB / UTILIZACIÓN DE COOKIES Y OTROS SISTEMAS Y TECNOLOGÍAS</strong>

                <p class="mt-3">
                    Cuando el <strong>USUARIO</strong> visita nuestro <strong>SITIO WEB</strong>, algunos de los datos de navegación se envían desde su computador a 
                    nuestros servidores. Esta información permite optimizar nuestros servicios, personalizar y mejorar su experiencia en nuestro <strong>SITIO WEB</strong>. Esta 
                    información puede incluir aspectos tales como pero no limitados a:
                </p>

                <ul>
                    <li>
                        <p>
                            Su dirección IP
                        </p>
                    </li>

                    <li>
                        <p>
                            La fecha y hora de su visita
                        </p>
                    </li>

                    <li>
                        <p>
                            La URL de referencia 
                        </p>
                    </li>

                    <li>
                        <p>
                            Las páginas que visitó en nuestro <strong>SITIO WEB</strong>
                        </p>
                    </li>

                    <li>
                        <p>
                            Información sobre el navegador que utilizó 
                        </p>
                    </li>

                    <li>
                        <p>
                            Tiempo que estuvo en el <strong>SITIO WEB</strong>
                        </p>
                    </li>

                    <li>
                        <p>
                            Cantidad de veces que ingreso al <strong>SITIO WEB</strong> y las veces que visito alguna página del <strong>SITIO WEB</strong>
                        </p>
                    </li>
                </ul>

                <p>
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> utiliza varias tecnologías para recopilar información cuando el <strong>USUARIO</strong> 
                    visita nuestro <strong>SITIO WEB</strong>, y esto puede incluir el envío de una o más cookies u otros identificadores para su dispositivo. 
                    Los sistemas y tecnologías que utilizamos son los siguientes:
                </p>

                <p>
                    <strong>IIV.1 Cookies o Galletas Informáticas</strong>
                </p>

                <p>
                    Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea
                    capaz de recordar su visita cuando vuelva a navegar por esa página. 
                </p>

                <p>
                    Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces 
                    a redes sociales, acceso a cuentas de USUARIO, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin cookies
                     los servicios ofrecidos por cualquier página se verían mermados notablemente. 
                </p>

                <P>
                    Nuestras cookies se utilizan para analizar su forma de interactuar con nuestro <strong>SITIO WEB</strong> con el fin de optimizar nuestros servicios
                    y para mejorar su experiencia. Por la manera en que funciona el <strong>SITIO WEB</strong>, si el <strong>USUARIO</strong>  desactiva o bloquea la 
                    utilización de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilización del <strong>SITIO WEB</strong>.
                </P>

                <p>
                    <strong>IV.2 Web server logs</strong>
                </p>

                <p>
                    Un web server log es un archivo simple de texto que graba la actividad en un servidor. Este tipo de sistema permite obtener estadísticas sobre
                     los patrones de navegación de los usuarios. Por la manera en que funciona el <strong>SITIO WEB</strong>, si el <strong>USUARIO</strong> desactiva 
                     o bloquea la utilización de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilización del <strong>SITIO WEB</strong>.
                </p>

                <p>
                    <strong>IV.3 Tecnología de Geo-Localización</strong>
                </p>

                <p>
                    La tecnología de geo-localización permite la determinación de la ubicación del <strong>USUARIO</strong> por medio de las coordinadas geográficas
                    (latitud y longitud) en un mapa común. La información será obtenida por medio de la identificación del IP de un aparato conectado a internet, 
                    por medio de un proveedor de red, por medio de un aparato con red GSM o por medio de un GPS. Por la manera en que funciona el  <strong>SITIO WEB</strong>,
                    si el <strong>USUARIO</strong> desactiva o bloquea la utilización de este sistema, se pueden ocasionar errores en el funcionamiento y/o la utilización del 
                    <strong>SITIO WEB</strong>.
                </p>

                <p>
                    Para evitar que su aparato permita el uso de las tecnologías antes dichas, el <strong>USUARIO</strong> debe rechazar la solicitud de autorización que se 
                    desplegará en la pantalla de su aparato, previo a la navegación por determinadas secciones de nuestro <strong>SITIO WEB</strong>,
                </p>

                <p>
                    Si el <strong>USUARIO</strong> tiene alguna duda sobre las tecnologías antes dichas, cómo funcionan, cómo desactivarlas o cualquier otro tipo de 
                    inquietud en relación con las que se utilizan en nuestro <strong>SITIO WEB</strong>, deberá comunicarse con nosotros al correo electrónico 
                    <a href="mailto:info@woodscostarica.com"><label>info@woodscostarica.com</label></a>. 
                </p>

                <p>
                    El <strong>USUARIO</strong>, de forma expresa, voluntaria, unilateral, clara e inequívoca acepta, reconoce y autoriza que con la información que 
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> recopile mediante el uso de Cookies, se generen perfiles del <strong>USUARIO</strong>
                    en relación con los criterios de búsqueda y selección frente a los servicios y productos de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>. 
                    Dicha generación de perfiles se realizará de forma automatizada, sin embargo, si el <strong>USUARIO</strong>  así lo pidiere por escrito y dirigido 
                    al correo <a href="mailto:info@woodscostarica.com"><label>info@woodscostarica.com</label></a>. <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>
                    podrá generar estos perfiles de forma manual y con intervención humana.
                </p>

            </li>

            <li>
                <strong>REORIENTACIÓN </strong>

                <p class="mt-3">
                    Nuestro <strong>SITIO WEB</strong> puede utilizar tecnologías de reorientación. Esto nos permite mostrar nuestros anuncios en sitios web asociados a 
                    los visitantes que estén interesados en nuestros productos. Reorientación de las tecnologías analizan las cookies y muestra anuncios en base a su 
                    comportamiento de navegación pasado. 
                </p>

            </li>

            <li>
                <strong>ASPECTOS DE SEGURIDAD DE LOS DATOS Y TRANSACCIONES</strong>

                <p class="mt-e">
                    Estamos comprometidos a proteger su <strong>INFORMACION PERSONAL</strong> y hemos adoptado las medidas técnicas y organizativas adecuadas para proteger 
                    dicha información contra cualquier pérdida, alteración, acceso o uso indebido
                </p>

                <p>
                    Todos los datos entregados por los <strong>USUARIOS</strong> se almacenan en nuestras bases de datos, utilizando un esquema de seguridad de Aplicación, 
                    usuario y Password generados y encriptados, que nos permiten aislar y controlar que cada uno de nuestros <strong>USUARIOS</strong>  solo tengan acceso a 
                    su información y que la seguridad (Usuario y Password) sea administrada directamente por <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> , a 
                    través del <strong>SITIO WEB</strong>.
                </p>

                <p>
                    La seguridad empleada en relación con el medio de pago es por medio de Certificados Digitales, así como usuarios y contraseñas generadas y encriptadas por 
                    medio de ASP. De igual, forma todos los canales de comunicación con las entidades financieras, están certificados y autorizados por dichas empresas, 
                    además de cumplir con todas las pautas establecidas por VISA Internacional y MasterCard.
                </p>

                <p>
                    Nuestra plataforma cuenta con las certificaciones correspondientes de Visa Internacional y Mastercard para la interconexión y operación en su plataforma.
                </p>
            </li>

            <li>
                <strong>LIBERACIÓN DE RESPONSABILIDAD</strong>

                <p class="mt-3">
                    El <strong>USUARIO</strong>  reconoce y acepta que el uso del <strong>SITIO WEB</strong> se realiza en todo momento bajo su entero riesgo y responsabilidad.
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> no será responsable por el uso indebido que pueda realizarse del <strong>SITIO WEB</strong>.
                </p>

                <p>
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> no será responsable por daños y perjuicios de cualquier tipo causados en los equipos electrónicos 
                    (cómputo, teléfono, tableta, u otros) del <strong>USUARIO</strong> por virus, gusanos o cualquier otro elemento dañino. El <strong>USUARIO</strong> reconoce 
                    que el uso de la red Internet supone la asunción de un riesgo de que sus equipos informáticos puedan ser afectados por los elementos antes enunciados. 
                    A tal efecto, corresponde al <strong>USUARIO</strong> en todo caso, la disponibilidad de herramientas adecuadas para la detección y eliminación de 
                    programas electrónicos dañinos que puedan suponer un riesgo para su equipo de cómputo.
                </p>

                <p>
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> no tendrá responsabilidad por la falta de ejecución de sus obligaciones cuando medie fuerza mayor, 
                    caso fortuito o la entrada en vigencia de legislación gubernamental, regulaciones o restricciones de cualquier otra índole que le impidan la ejecución de sus obligaciones.
                </p>
            </li>

            <li>
                <strong>INFORMACION QUE EL USUARIO PUBLICA</strong>
                
                <p class="mt-3">
                    Cuando el <strong>USUARIO</strong> postea o publica información en el <strong>SITIO WEB</strong>, tal como pero no limitada a fotografías, comentarios e imágenes, 
                    la está poniendo a disposición de otros <strong>USUARIOS</strong> del <strong>SITIO WEB</strong> y de cualquier persona que visite el <strong>SITIO WEB</strong>.
                </p>

                <p>
                    El <strong>USUARIO</strong> reconoce que esta información publicada por el <strong>USUARIO</strong> está fuera del control de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> 
                    y, por ende, el <strong>USUARIO</strong> asume la responsabilidad por su contenido, por reclamos de terceros en relación con lo publicado así como por el uso indebido de dicha 
                    información por parte de otros <strong>USUARIOS</strong> o terceros y libera de responsabilidad a <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong>, sus accionistas, representantes, 
                    subsidiarias y compañías relacionadas por el mal uso que cualquier tercero pueda realizar en relación con dicha información, así como por cualquier reclamo, 
                    demanda o conflicto que se origine en el contenido de dicha publicación. Por lo anterior, el <strong>USUARIO</strong> debe ser cuidadoso y responsable a la hora de 
                    decidir el contenido que postea o publica en el <strong>SITIO WEB</strong>.
                </p>
            </li>

            <li>
                <strong>
                    CAMBIOS EN LA POLITICA DE PRIVACIDAD
                </strong>

                <p class="mt-3">
                    <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> se reserva la posibilidad de realizar cambios o ajustes a la presente Política de Privacidad. Si 
                    ejecutamos modificaciones o ajustes a esta Política de Privacidad se lo notificaremos a la hora de utilizar los Servicios o por alguno de los medios indicados 
                    por el <strong>USUARIO</strong> para recibir comunicaciones de parte de <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA</strong> . La utilización de alguno de 
                    los Servicios implica la aceptación del <strong>USUARIO</strong> de las modificaciones o ajustes a esta Política de Privacidad. 
                </p>

                <p>
                    Recomendamos a nuestros <strong>USUARIOS</strong> revisar periódicamente nuestra Política de Privacidad estar informado sobre posibles cambios en la misma.
                </p>

                <p>
                    Si tiene alguna duda que le inhibe de aceptar esta política y términos de uso, puede aclararla escribiéndonos al correo <a href="mailto:info@woodscostarica.com">
                    <label>info@woodscostarica.com</label></a>.
                </p>
            </li>
            



        </ol>

    </div>
    

</div>

<woods-footer></woods-footer>