<woods-navbar></woods-navbar>

<div class="mb-5">
    <div class="jumbotron jumbotron-fluid text-light text-center ">
        <div class="woods-heading-text-container">
            <h1 class=" display-5">Epóxicos de base biológica de vertido profundo</h1>
            <h2 class=" ">Resinas epoxi de primera calidad de acabado profesional</h2>
        </div>
        
    </div>

    <div class="mt-5 container">
        <div class="woods-casting">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Casting</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>Flowcast</h3>
                    <hr class="custon-color-texto">
                    <p>Para vertidos de más de 1/2 Pulgada de espesor, ¡estos epoxis son para usted! Los epoxis de <strong>Flowcast</strong> de EcoPoxy son perfectos para vertidos profundos y son conocidos por su durabilidad y su unión extremadamente fuerte a los objetos a los que curan. Perfectos para proyectos grandes que incluyen mesas de río, muebles, y otras obras de arte.</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                     <div class="woods-contenedor-img-texto">
                        <img [src]="'../../../assets/img/ecopoxi/placeholder.png'"  class="custom-product-img">
                     </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>

    <div class="mt-5 container">
        <div class="woods-snowwhite">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Casting</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>SnowWhite</h3>
                    <hr class="custon-color-texto">
                    <p><strong>SnowWhite</strong> es un sistema epoxi de dos componentes, altamente pigmentado de color blanco intenso, desarrollado para aplicaciones de fundición grandes, como mesas de río y muebles. El color blanco puro de alta opacidad contrasta maravillosamente y acentúa la veta de la madera en proyectos de bordes vivos.</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>
    
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                     <div class="woods-contenedor-img-texto">
                        <img [src]="'../../../assets/img/ecopoxi/SnowWhite.png'"  class="custom-product-img">
                     </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>


    <div class="mt-5 container ">
        <div class="woods-contenedor-calculadora-page">
            <div class="row">

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 woods-contenedor-calculadra"> 
                    <div class="woods-contenedor-calculadra-texto">
                        <h2>Calculadora de volumen!</h2>
                        <hr class="custon-color-texto">
                        <p>Les presentamos nuestra nueva herramienta que te ayudará en tus proyectos con resina ecopoxy..</p>
                        <a class="btn btn-woods-ecopoxi-outline" [routerLink]="['/resina-calculadora']">Ir a la calculadora</a>
                    </div>
                   
                </div>
    
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <div class="woods-contenedor-calculadora-img">
                        <img [src]="'../../../assets/img/ecopoxi/calculadoraDeVolumenEcopoxy.png'"  class="custom-product-img-calciladora">
                    </div>
                    
                </div>
    
            </div>
        </div>
        
       
       
        
    </div>


    <div class="mt-5 container">

        <div class="woods-uvpoxy">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Coating</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>UVPoxy</h3>
                    <hr class="custon-color-texto">
                    <p><strong>UVPoxy</strong> es un sistema epoxi transparente de dos componentes de alto rendimiento diseñado para superficies de mesa, acabados de madera, incrustaciones de objetos, obras de arte y otras aplicaciones que requieren un revestimiento epoxi transparente y fuerte; diseñado específicamente para resistir el amarillamiento causado por el sol y otras fuentes de luz ultravioleta.</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                        <img [src]="'../../../assets/img/ecopoxi/uvpoxy.png'"  class="custom-product-img">
                    </div>
                    
                </div>
            </div>
            
        </div>
       
        
    </div>

    <div class="mt-5 container">

        <div class="woods-soidos">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Accessories</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>Liquid Color Pigments</h3>
                    <hr class="custon-color-texto">
                    <p>¡Crea efectos únicos! Todos nuestros productos de color y aditivos epoxi, están especialmente formulados para suspenderse en nuestros revestimientos y epoxis de vaciado. Podras mezclar y fraguar uniformemente durante el curado.</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                        <img [src]="'../../../assets/img/ecopoxi/soidos.png'"  class="custom-product-img">
                    </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>

    <div class="mt-5 container">

        <div class="woods-metalico">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Accessories</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>Metallic Color Pigments</h3>
                    <hr class="custon-color-texto">
                    <p>Nuestros pigmentos de color metálicos están compuestos por nanopartículas de mica recubiertas con varios pigmentos orgánicos e inorgánicos para crear efectos nacarados e iridiscentes.</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                        <img [src]="'../../../assets/img/ecopoxi/metalico.png'"  class="custom-product-img">
                    </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>

    <div class="mt-5 container">

        <div class="woods-glitter">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Accessories</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>Polyester Color Glitters</h3>
                    <hr class="custon-color-texto">
                    <p>Los brillos de color de poliéster se cortan con precisión de una película de poliéster metalizada al vacío de primera calidad. La naturaleza brillante de estos brillos especiales,  significa que son compatibles con las resinas EcoPoxy y las complementan naturalmente. Mezcle los brillos de color de poliéster con una capa superior delgada como UVPoxy, y las motas permanecerán suspendidas y visibles en la capa superior, ¡lo que le dará a su proyecto un brillo lustroso!</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                        <img [src]="'../../../assets/img/ecopoxi/Red.png'"  class="custom-product-img">
                    </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>

    <div class="mt-5 container" name="pourpail" id="pourpail">

        <div class="woods-pourpail">
            <div class="woods-contenedor-h4">
                <h4 class="woods-h4-ecopoxy">Accessories</h4>
            </div>
            
        </div>

        <div class="woods-contenedor-info">
            <div class="row">
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <h3>Pour Pail</h3>
                    <hr class="custon-color-texto">
                    <p>Eleve el flujo de trabajo de su proyecto con el innovador EcoPoxy Pour Pail. El revolucionario diseño del mango hace que verter sea fácil y sin complicaciones. Otra adición innovadora al conjunto de herramientas para artistas y artesanos.</p>
                    
                    <a class=" btn btn-woods-ecopoxi" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        Más información <i class="fa fa-whatsapp fa-1x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                        <img [src]="'../../../assets/img/ecopoxi/pourpail.png'"  class="custom-product-img">
                    </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>

    <div class="mt-5 container">

        <div class="woods-contenedor-calculadora-page">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 woods-contenedor-calculadra">
                    <div class="woods-contenedor-calculadra-texto">
                        <h3>Vea nuestro catálogo completo de productos en línea</h3>
                        <button class="btn btn-woods-ecopoxi" (click)="openPdfResina()">
                            Buscar más
                        </button>
                    </div>
                   
                    
                </div>
    
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                        <img [src]="'../../../assets/img/ecopoxi/catalogo.png'"  class="custom-product-img">
                    </div>
                    
                </div>
                
    
            </div>
        </div>
       
       
    </div>


    
</div>

<woods-footer></woods-footer>
