<div class="custom-product-container card card-body ">
    <div class="row">

        <div class="col-12">
            <img [src]="'../../../assets/img/productos/foto/' + producto.Foto +'.jpg'" width="275" height="400">
        </div>

        <div class="col-12">
            <label class="woods-text-black">{{producto.Nombre}}</label>
        </div>

        <div class="col-12">
            <label class="woods-text-gray">CRC {{ producto.Precio }}</label>
        </div>

        <div class="col-12">

            <a class="btn btn-woods-primary" [routerLink]="['/producto', producto.ID]">Más detalles</a>

        </div>

    </div>
    
</div>
