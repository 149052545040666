<woods-navbar></woods-navbar>

<div class="mb-5">

    <div class="text-center mt-5">
        <img src="../../../assets/img/logos/WOODSCR GOLD.png" width="250" height="250" alt="Woods Costa Rica">
    </div>

    <div class="ml-5 mr-5 mt-5">

        <h3>Políticas de Devolución, Cambio y Retiro</h3>

        <h5 class="mt-5">Política de cambio y devolución</h5>

        <p class="mt-3">En caso de que querás cambiar la maderaque compraste por otra clase , otro color u otra madera de Maderas del Reventazon Sociedad Anonima, tenés 7 días hábiles para realizar el cambio:</p>
        
        <div class="mt-3">
            <ul>
                <li>La madera debe estar nueva, con etiqueta colocada y en las misma condiciones que estaba cuando la recibiste. El equipo Maderas del Reventazon Sociedad Anonima revisará el estado de la madera antes de confirmar el cambio</li>
                
                <li>En caso de que el cambio de la maderasea por concepto de garantía, tenés 30 días para realizar el cambio. </li>

                <li>Es indispensable presentar la factura electrónica para realizar el cambio</li>

                <li>Se debe presentar la factura electrónica para realizar el cambio. </li>

                <li>Cuando realices el cambio, podes hacerlo de las siguientes maneras:</li>

                <li>En nuestras tiendas físicas ubicada en Curridabat y Escazú</li>

                <li>En este caso, con excepción de ser un cambio por concepto de garantía, vos asumís el costo del envío de la Madera, tanto el costo del envío de la Madera a que querés cambiar como el envío de la nueva Madera.</li>

                <li>Madera utilizadas o lavada no serán cambiadas. </li>

            </ul>
        </div>

        <p>Casos no aplica la garantía y por ende donde no se procederá con el cambio:</p>
        <div class="mt-3">
            <ul>
                <li>Si el cambio es por algún daño ocasionado a la Madera y no por concepto de garantía.</li>

                <li>Madera expuestas al uso de productos químicos que decoloren las Madera.</li>

                <li>Maderas alteradas o modificadas por el cliente.</li>

                <li>Uso incorrecto de las instrucciones de cuidado y manejo de la madera.</li>

            </ul>

        </div>

        <p> La garantía cubre:</p>

        <div class="mt-3">
            <ul>
                <li>Si Madera presenta alguna irregularidad y se tuvieron en cuenta todas las recomendaciones de cuidado y manejo de la madera.</li>

                <li>Defectos de fabricación.</li>

            </ul>
        </div>

        <h5 class="mt-5">Política de retiro</h5>
        <p class="mt-3">¡Estamos felices de que vas a retirar tu compra en nuestras tiendas, eso significa que nos vas a visitar!</p>
        <div class="mt-3">
            <ul>
                <li>Tu compra estará disponible dentro de un rango de 24 a 72 horas hábiles</li>

                <li>Una vez que tu compra esté lista para retirar en la tienda física, te enviaremos un mensaje de texto por medio de WhatsApp al número indicado en la orden.</li>

                <li>Para retirar el pedido en la tienda, es importante que presentes tu factura electrónica en el área de cajas.</li>

            </ul>
        </div>
        <p>En caso de que vos no seas quien retire el pedido personalmente, es importante que nos notifiques al correosoporte@woodscostarica.com el nombre y número de cédula de la persona que retira antes de que se presente a la tienda. Importante que no olvide la factura electrónica para que así pueda retirar el pedido sin problema.</p>

    </div>

</div>

<woods-footer></woods-footer>
