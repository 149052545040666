<div class="woodsFooterBody  pt-4 pb-4">

    <div class="row  ml-3 mr-3">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="text-center">
                <img src="../../../../assets/img/logos/logo-blanco.png" width="170" height="45" alt="Woods Costa Rica">
            </div> 
            <div class="mt-3 text-justify ">
                <p class="text-light">Este sitio almacena cookies. Esas cookies son usadas para recolectar información de como usa nuestro sitio y recordarlo. Usamos esa información para mejorar su experiencia de navegación, análisis y métricas de los visitantes de este sitio y otros medios. Para descubirir la información recolectada lea nuestra Política de Privacidad. Al usar este sitio, usted acepta nuestros términos de uso y política de privacidad.</p>
            </div>

           
        </div>
        

        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">

            <!-- URLS VArios Internnos  pagina  -->
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <a class="navbar-brand" href="#/politica-privacidad">
                        <small>Política de privacidad</small>
                    </a>
                    
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <a class="navbar-brand" href="#">
                        <small>Blog</small>
                    </a>
                    
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <a class="navbar-brand" href="#/about">
                        <small>Acerca de woodscostarica.com</small>
                    </a>
                    
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <a class="navbar-brand" href="#/terminos-y-condiciones">
                        <small>Limitación de responsabilidad</small>
                    </a>
                    
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <a class="navbar-brand" href="#">
                        <small>(506) 2253-1735</small>
                    </a>

                </div>
                
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <a class="navbar-brand" href="#">
                        <small>Login Interno</small>
                    </a>
                    
                </div >

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" (click)="gotTop()">
                    <a class="navbar-brand" href="#/politica-devolucion" >
                        <small>Políticas de devolución y Envío o Retiro</small>
                    </a>
                    
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" (click)="gotTop()">
                    <a class="navbar-brand" href="#/contacto" >
                        <small>Contáctenos</small>
                    </a>
                    
                </div>

                
            </div>

            <!-- URLS Redes socciales  -->
            <div class="align-text-bottom row pl-3 pr-3">

                <div class="col-auto">
                    <a href="https://www.waze.com/live-map/directions?to=ll.9.929988%2C-84.043672" target="_blank">
                        <i class="fa fa-map-marker fa-5x woods-point-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-auto">
                    <a href="https://www.facebook.com/maderasdelreventazon/" target="_blank">
                        <i class="fa fa-facebook fa-2x woods-facebook-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-auto">
                    <a href="https://www.instagram.com/maderasdelreventazon/" target="_blank">
                        <i class="fa fa-instagram fa-2x woods-instagram-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-auto">
                    <a href="mailto:ventas@woodscostarica.com">
                        <i class="fa fa-envelope fa-2x woods-mail-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <div class="col-auto">
                    <a href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0">
                        <i class="fa fa-whatsapp fa-2x woods-whatsapp-icon" aria-hidden="true"></i>
                    </a>
                </div>

                <!--<div class="col-auto">
                    <a href="https://www.pinterest.com/04fuzn5ql7uf3wro7hh93w5sr6cvzo/">
                        <i class="fa fa-pinterest fa-2x woods-icon" aria-hidden="true"></i>
                    </a>
                </div>-->
                
            </div>
            
        </div>
    </div>

</div>
