import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import{ RestApiService } from "./services/rest-api.service"

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  cargando = true;

  busqueda :any;
  DataCategoria: any;

  ListaDeProductos:any =[];
  cantProductos =0;

  constructor( public route: ActivatedRoute, private restApi:RestApiService) {
    window.scrollTo(0,0);
    route.params.subscribe(parametro =>{
      this.cargando =true;
      this.busqueda = parametro['busqueda'];

      this.restApi.BuscarProductos(this.busqueda).subscribe((res:any)=>{ 
        this.ListaDeProductos = res;
        this.cantProductos = this.ListaDeProductos.length;
        this.cargando =false;

      });

    })
   }

  ngOnInit(): void {
  }

}
