import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-galeria-videos',
  templateUrl: './galeria-videos.component.html',
  styleUrls: ['./galeria-videos.component.scss']
})

export class GaleriaVideosComponent implements OnInit {
  galeriaAbril25 = [
    {
      ID:0,
      titulo: 'Resina de vertido profundo', 
      description: 'En este video se muestra el proceso de vertido de resina en una pieza previamente sellada.',
      image: 'https://res.cloudinary.com/dvkidjwvy/video/upload/v1681585669/Woods/Video/Curso%20Resina%2025-03-2023/WhatsApp_Video_2023-04-15_at_12.29.02_PM_zuwhqa.mp4'
    },
    {
      ID:1,
      titulo: 'Resina con pigmento metálico', 
      description: 'En este video se muestra la preparación de la resina de vertido profundo con pigmento metálico color verde.',
      image: 'https://res.cloudinary.com/dvkidjwvy/video/upload/v1681595396/Woods/Video/Curso%20Resina%2025-03-2023/WhatsApp_Video_2023-04-15_at_3.49.19_PM_yfxyu8.mp4'
    }/*,
    {
      ID:2,
      titulo: 'Aplicacion de capa para acabado superficial', 
      description: 'En este video se muestrala la aplicacion de resina Ecopoxy para un acabado superficial sobre una pieza de madera con un trabajo previo en resina.',
      image: 'https://res.cloudinary.com/dvkidjwvy/video/upload/v1681590668/Woods/Video/Curso%20Resina%2025-03-2023/WhatsApp_Video_2023-04-15_at_2.26.49_PM_a7h5wb.mp4'
    }*/
  ];
  constructor() { }

  ngOnInit(): void {
  }

  

}
