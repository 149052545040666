<woods-navbar></woods-navbar>

<div class="mb-5">
    <img src="../../../../assets/img/home/home-banner/bannerPeq.png" width="100%" alt="Woods Costa Rica">
    <br>

    <div *ngIf="cargando">
        <div class="text-center mt-5">
            <woods-loading></woods-loading>
            <h3 class="mt-3">Cargando...</h3>
        </div>
        
    </div>
    <div *ngIf="!cargando">
        <div class="mr-5 ml-5 mt¿5">
            <br>
            <h1 class="display-3">
                <strong class=" text-muted "> {{ DataCategoria.Nombre }} </strong> 
                <small class="h4 text-muted" *ngIf="IdCategoria==22">Las mesas se producen contra pedido y se cotizan solamente en la tienda física.</small> 
            </h1>
            
            <hr>
            
            <div *ngIf="ListaDeProductos.length  === 0">
                <div class="text-center mt-5">
                    <i class="fa fa-exclamation-triangle fa-5x woods-warning-icon" aria-hidden="true"></i>
                    <h5 class="mt-3">Estimado Usuario, actualmente no contamos con productos dentro de esta categoria</h5>
                </div>
            </div>
    
            
            <div *ngIf="ListaDeProductos.length  !== 0">
                <div class="gridContainer">
                    <div *ngFor="let elemento of ListaDeProductos" class="gridContainer">
                        <woods-product-container [producto]="elemento" > </woods-product-container>
    
                    </div>
                    
                </div>
                
            </div>
    
        </div>
    </div>

    
    
</div>


<woods-footer></woods-footer>
