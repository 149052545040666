import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router'
import { Internaldata } from "./services/internaldata.service"


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  esValidoNombreCompleto = true
  esValidoCedula = true
  esValidoTelefono = true
  esValidoCorreo = true
  esValidoMensaje = true


  esValidoRangoDeEdad = true
  esValidoTerminosYCondiciones = true


  vForm: FormGroup = this.formBuilder.group({
    NombreCompleto: ['',Validators.required ],
    Cedula:['',Validators.required],
    Telefono:['',Validators.required],
    Correo: ['',Validators.required],

    Mensaje: ['',Validators.required],
  });

  constructor(private formBuilder: FormBuilder, public enviarCorreo:Internaldata,  private router:Router,) { }

  ngOnInit(): void {
  }

  ValidarFormularioNombreCompleto(){
    if (this.vForm.get("NombreCompleto").value === ''){
      this.esValidoNombreCompleto = false;
    }
    else{
      this.esValidoNombreCompleto = true;
    } 
  }

  ValidarFormulariCedula(){
    if (this.vForm.get("Cedula").value === ''){
      this.esValidoCedula = false;
    }
    else{
      this.esValidoCedula = true;
    } 
  }

  ValidarFormularioTelefono(){
    if (this.vForm.get("Telefono").value === ''){
      this.esValidoTelefono = false;
    }
    else{
      this.esValidoTelefono = true;
    } 
  }

  ValidarFormularioCorreo(){
    if (this.vForm.get("Correo").value === ''){
      this.esValidoCorreo = false;
    }
    else{
      this.esValidoCorreo = true;
    }
  }

  ValidarFormularioMensaje(){
    if (this.vForm.get("Mensaje").value === ''){
      this.esValidoMensaje = false;
    }
    else{
      this.esValidoMensaje = true;
    }
  }

  ValidarFormulario(){
    this.ValidarFormularioNombreCompleto();
    this.ValidarFormulariCedula();
    this.ValidarFormularioTelefono();
    this.ValidarFormularioCorreo();
    this.ValidarFormularioMensaje();

  }

  limpiarFOrmulario(){

    this.vForm.get("NombreCompleto").setValue("");
    this.vForm.get("NombreCompleto").markAsUntouched;
    this.vForm.get("NombreCompleto").markAsPristine;

    this.vForm.get("Cedula").setValue("");
    this.vForm.get("Cedula").markAsUntouched;
    this.vForm.get("Cedula").markAsPristine;

    this.vForm.get("Telefono").setValue("");
    this.vForm.get("Telefono").markAsUntouched;
    this.vForm.get("Telefono").markAsPristine;

    this.vForm.get("Correo").setValue("");
    this.vForm.get("Correo").markAsUntouched;
    this.vForm.get("Correo").markAsPristine;

    this.vForm.get("Mensaje").setValue("");
    this.vForm.get("Mensaje").markAsUntouched;
    this.vForm.get("Mensaje").markAsPristine;


  }


  enviarInformacion(){

    this.ValidarFormulario()

    if( this.esValidoNombreCompleto && this.esValidoCedula && this.esValidoTelefono && this.esValidoCorreo && this.esValidoMensaje ){

      let datosFormulario = {
        NombreCompleto: this.vForm.get("NombreCompleto").value,
        Cedula: this.vForm.get("Cedula").value,
        Telefono: this.vForm.get("Telefono").value,
        Correo: this.vForm.get("Correo").value,
        Mensaje: this.vForm.get("Mensaje").value,
      }
  
     
      this.enviarCorreo.enviarInformacion(datosFormulario)
      let modal = document.getElementById('toggleSendModal')
      modal.click();

      this.limpiarFOrmulario();

      //this.router.navigate( ['/home'] );
    }
    
  }

  

}
