<woods-navbar></woods-navbar>
<div class="">

    <div class="my-5 mx-3">
        <h1 class="display-3">
            <strong class=" text-muted ">Participe en nuestros talleres</strong>
        </h1>
        
        <hr>

        <div class="text-center mt-3">

              <woods-video-slider [sections]="galeriaAbril25"></woods-video-slider>

    
            <hr>
            <h3 class="text-muted"> Participantes</h3>
            <img class="rounded card-img-top woods-custom-video" src="https://res.cloudinary.com/dvkidjwvy/image/upload/v1681585654/Woods/Video/Curso%20Resina%2025-03-2023/WhatsApp_Image_2023-04-15_at_1.04.47_PM_byvrub.jpg" alt=""> 

        </div>



    </div>

    
</div>

<woods-footer></woods-footer>