import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import{ RestApiService } from "./services/rest-api.service"


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  Producto :any ;
  IdProducto = ''
  categoria = ''
  productosMismaCategoria:any;
  cargando = true;
  imgSeleccionada = "";

  seleccionado1=false;
  seleccionado2=false;
  seleccionado3=false;
  seleccionado4=false;


  constructor(public route: ActivatedRoute, private restApi:RestApiService) {
    window.scrollTo(0,0);

    route.params.subscribe(parametro =>{
      this.cargando =true;
      this.IdProducto = parametro['id'];
      
      this.restApi.getDatosProducto(this.IdProducto ).subscribe((res:any)=>{
      
      this.Producto = res[0];
      this.categoria  = this.Producto.Categoria;
      this.imgSeleccionada = this.Producto.Foto;
      this.seleccionado1=true;
      this.seleccionado2=false;
      this.seleccionado3=false;
      this.seleccionado4=false;


      this.restApi.obtenerProductos(this.categoria).subscribe((resProductos:any)=>{
        this.productosMismaCategoria = resProductos;

        this.cargando =false;
      });


      });

      
    });

   }

  ngOnInit(): void {
  }


  clickImg(entrada:string){
    window.scrollTo(0,0);
    switch(entrada){
      case "1":
        this.imgSeleccionada = this.Producto.Foto;

        this.seleccionado1=true;
        this.seleccionado2=false;
        this.seleccionado3=false;
        this.seleccionado4=false;
        break;
      case "2":
        this.imgSeleccionada = this.Producto.Foto2;

        this.seleccionado1=false;
        this.seleccionado2=true;
        this.seleccionado3=false;
        this.seleccionado4=false;
        break;
      case "3":
        this.imgSeleccionada = this.Producto.Foto3;

        this.seleccionado1=false;
        this.seleccionado2=false;
        this.seleccionado3=true;
        this.seleccionado4=false;
        break;
        case "4":
          this.imgSeleccionada = this.Producto.Foto4;
          
          this.seleccionado1=false;
          this.seleccionado2=false;
          this.seleccionado3=false;
          this.seleccionado4=true;
        break;
    }

  }


}
