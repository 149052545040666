import { Routes, RouterModule } from '@angular/router';

/* ========== Import de los componente pagina ========== */
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PoliticaDevolucionComponent } from './components/pages/politica-devolucion/politica-devolucion.component';
import { PoliticaPrivacidadComponent } from './components/pages/politica-privacidad/politica-privacidad.component';
import { TerminosYCondicionesComponent } from './components/pages/terminos-y-condiciones/terminos-y-condiciones.component';
import { CategoriaComponent } from './components/pages/categoria/categoria.component';
import { ProductComponent } from './components/pages/product/product.component';
import { PromocionesProductoComponent } from './components/pages/promociones-producto/promociones-producto.component';

import { ProductListComponent } from './components/pages/product-list/product-list.component';
import { ContactoComponent } from './components/pages/contacto/contacto.component';
import { GaleriaComponent } from './components/pages/galeria/galeria.component';
import { EcopoxyBaseComponent } from './components/pages/ecopoxy-base/ecopoxy-base.component';
import { EcopoxyCalculadoraComponent } from './components/pages/ecopoxy-calculadora/ecopoxy-calculadora.component';
import { GaleriaVideosComponent } from './components/pages/galeria-videos/galeria-videos.component';
import { CrearCitaComponent } from './components/pages/crear-cita/crear-cita.component';













const APP_Routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'about', component: AboutComponent},
  { path: 'politica-devolucion', component: PoliticaDevolucionComponent},
  { path: 'politica-privacidad', component: PoliticaPrivacidadComponent},  
  { path: 'terminos-y-condiciones', component: TerminosYCondicionesComponent},  
  { path: 'categoria/:id', component: CategoriaComponent},  
  { path: 'producto/:id', component: ProductComponent},  
  { path: 'producto-promocion/:id', component: PromocionesProductoComponent},  
  { path: 'lista-productos/:busqueda', component: ProductListComponent},  
  { path: 'contacto', component: ContactoComponent},  
  { path: 'galeria', component: GaleriaComponent},  
  { path: 'resina', component: EcopoxyBaseComponent},  
  { path: 'resina-calculadora', component:EcopoxyCalculadoraComponent },  
  { path: 'galeria-videos', component:GaleriaVideosComponent },  
  { path: 'crear-cita', component:CrearCitaComponent },  



  




  { path: '**', pathMatch: "full", redirectTo: 'home'},

];

export const APP_ROUTING = RouterModule.forRoot(APP_Routes);
