import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import { element } from 'protractor';
//import * as mysql from "mysql"
import{ RestApiService } from "./services/rest-api.service"
import { timer } from 'rxjs';


@Component({
  selector: 'woods-navbar',
  templateUrl: './woods-navbar.component.html',
  styleUrls: ['./woods-navbar.component.scss']
})
export class WoodsNavbarComponent implements OnInit {

  categorias:any;
  noHayResultados=false;

  constructor( private restApi:RestApiService, private router:Router ) {


    this.restApi.obtenerCategorias().subscribe((res:any)=>{
      this.categorias = res.msg
      
    });
    
   }

  ngOnInit(): void {

  }


  Buscar(){
    
    let busqueda = document.getElementById("search-input") as HTMLInputElement

    if (busqueda.value!==""){
      this.restApi.BuscarCategoria(busqueda.value).subscribe((res:any)=>{

        if (res["COUNT(*)"] ===0){
          let modal = document.getElementById('toggleErrorModal')
          this.noHayResultados=true;
          modal.click();
          timer(5000).subscribe(x => { this.noHayResultados=false;})
        }
        else{
          this.router.navigate( ['/lista-productos',busqueda.value] );
          busqueda.value = '';
        }
      });
    }
    
  }

  handleKeyPress(e){
    var key=e.keyCode || e.which;
     if (key==13){
        this.Buscar()
     }
   }




}


