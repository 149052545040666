import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/environments/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  urlBuscar = "database/getbuscarproductos/?Busqueda=";

 
  constructor(private http: HttpClient) { }


  BuscarProductos(buscar:string){
    return this.http.get(AppSettings.BASE_URL + this.urlBuscar + buscar);
  }

}


