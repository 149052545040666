import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/* ===================================== */
/* ---------- Modulos de Node ---------- */
/* ===================================== */

import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



/* =========================== */
/* ---------- Rutas ---------- */
/* =========================== */

import { APP_ROUTING } from './app.routes'

/* =============================== */
/* ---------- Servicios ---------- */
/* =============================== */


/* ============================================== */
/* ---------- Componentes tipo Pagina  ---------- */
/* ============================================== */

import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ProductComponent } from './components/pages/product/product.component';
import { ProductListComponent } from './components/pages/product-list/product-list.component';

/* ================================================ */
/* ---------- Componentes personalizados ---------- */
/* ================================================ */

import { WoodsNavbarComponent } from './components/shared/woods-navbar/woods-navbar.component';
import { WoodsFooterComponent } from './components/shared/woods-footer/woods-footer.component';
import { WoodsCarouselComponent } from './components/shared/woods-carousel/woods-carousel.component';
import { WoodsImgContainerComponent } from './components/shared/woods-img-container/woods-img-container.component';
import { PoliticaDevolucionComponent } from './components/pages/politica-devolucion/politica-devolucion.component';
import { PoliticaPrivacidadComponent } from './components/pages/politica-privacidad/politica-privacidad.component';
import { TerminosYCondicionesComponent } from './components/pages/terminos-y-condiciones/terminos-y-condiciones.component';
import { CategoriaComponent } from './components/pages/categoria/categoria.component';
import { WoodsLoadingComponent } from './components/shared/woods-loading/woods-loading.component';
import { ProductContainerComponent } from './components/shared/product-container/product-container.component';
import { ContactoComponent } from './components/pages/contacto/contacto.component';
import { WoodsVideosComponent } from './components/shared/woods-videos/woods-videos.component';
import { WoodsWallyComponent } from './components/shared/woods-wally/woods-wally.component';
import { GaleriaComponent } from './components/pages/galeria/galeria.component';
import { WoodsCarouselGaleriaComponent } from './components/shared/woods-carousel-galeria/woods-carousel-galeria.component';
import { EcopoxyBaseComponent } from './components/pages/ecopoxy-base/ecopoxy-base.component';
import { EcopoxyCalculadoraComponent } from './components/pages/ecopoxy-calculadora/ecopoxy-calculadora.component';
import { GaleriaVideosComponent } from './components/pages/galeria-videos/galeria-videos.component';
import { WoodsVideoSliderComponent } from './components/shared/woods-video-slider/woods-video-slider.component';
import { CalendarioComponent } from './components/pages/calendario/calendario.component';
import { CrearCitaComponent } from './components/pages/crear-cita/crear-cita.component';
import { WoodsPromocionesComponent } from './components/shared/woods-promociones/woods-promociones.component';
import { PromocionesProductoComponent } from './components/pages/promociones-producto/promociones-producto.component';


/* ============================== */
/* ---------- NgModule ---------- */
/* ============================== */

@NgModule({
  declarations: [
  
    AppComponent,
    
    /* ============================================== */
    /* ---------- Componentes tipo Pagina  ---------- */
    /* ============================================== */
    HomeComponent,
    AboutComponent,
    ProductComponent,
    ProductListComponent,

    /* ================================================ */
    /* ---------- Componentes personalizados ---------- */
    /* ================================================ */
    WoodsNavbarComponent,
    WoodsFooterComponent,
    WoodsCarouselComponent,
    WoodsImgContainerComponent,
    PoliticaDevolucionComponent,
    PoliticaPrivacidadComponent,
    TerminosYCondicionesComponent,
    CategoriaComponent,
    WoodsLoadingComponent,
    ProductContainerComponent,
    ContactoComponent,
    WoodsVideosComponent,
    WoodsWallyComponent,
    GaleriaComponent,
    WoodsCarouselGaleriaComponent,
    EcopoxyBaseComponent,
    EcopoxyCalculadoraComponent,
    GaleriaVideosComponent,
    WoodsVideoSliderComponent,
    CalendarioComponent,
    CrearCitaComponent,
    WoodsPromocionesComponent,
    PromocionesProductoComponent,

  ],
  imports: [
    BrowserModule,
    
    /* =========================== */
    /* ---------- Rutas ---------- */
    /* =========================== */
    APP_ROUTING,

    /* ===================================== */
    /* ---------- Modulos de Node ---------- */
    /* ===================================== */
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    


    
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
