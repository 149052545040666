<div>
    <div class="text-center mt-3 woods-custom-videos-main-container pt-5 pb-3">

        <div *ngFor="let elemento of sections">

            <video class="woods-custom-video" *ngIf="elemento.ID === currentSectionIndex"[muted]="'muted'" controls autoplay loop>
                <source
                [src]="elemento.image"
                type="video/mp4">
                Your browser does not support HTML5 video.
            </video>
            
        </div>

        <h3>{{sections[currentSectionIndex].titulo}}</h3>
        <p>{{sections[currentSectionIndex].description}}</p>

    </div>


    <div class="mt-3">
        <button class="btn btn-primary" (click)="onPreviousSection()" [disabled]="currentSectionIndex == 0">Anterior</button>      
        <button class="ml-3 btn btn-primary" (click)="onNextSection()" [disabled]="currentSectionIndex == sections.length - 1">Siguiente</button>
      </div>   
</div>
