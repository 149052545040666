import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-woods-img-container',
  templateUrl: './woods-img-container.component.html',
  styleUrls: ['./woods-img-container.component.scss']
})
export class WoodsImgContainerComponent implements OnInit {

  /* ===================================================== */
  /* ---------- Entrada de datos del componente ---------- */
  /* ===================================================== */
  @Input() ImgURL;
  @Input() width ;
  @Input() height;
  

  constructor() { }

  ngOnInit(): void {
  }

}
