import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-privacidad',
  templateUrl: './politica-privacidad.component.html',
  styleUrls: ['./politica-privacidad.component.scss']
})
export class PoliticaPrivacidadComponent implements OnInit {


  cargando = true;

  constructor() {
    window.scrollTo(0,0);
   }

  ngOnInit(): void {
  }

}
