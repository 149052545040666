import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  urlsImgsCarousel = [
    "../../../assets/img/carousel/img01.jpg",
    "../../../assets/img/carousel/img02.jpeg",
    "../../../assets/img/carousel/img02.jpg",
    "../../../assets/img/carousel/img03.jpg"
  ]

  constructor( private router:Router) { }

  ngOnInit(): void {
  }

  clickCategoria(categoria:string){
    this.router.navigate( ['/categoria',categoria] );
  }

}
