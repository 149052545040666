import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router'

@Component({
  selector: 'app-crear-cita',
  templateUrl: './crear-cita.component.html',
  styleUrls: ['./crear-cita.component.scss']
})
export class CrearCitaComponent implements OnInit {

  /* ------------------- Variables generales ------------------- */

  esValidoNombreCompleto = true;
  esValidoCedula = true;
  esValidoTelefono = true;
  esValidoCorreo = true;
  esValidoTipoDeMadera = true;

  numeroPagformulario = 1;




   /* ------------------- Declaracion del Formularios ------------------- */

  vForm: FormGroup = this.formBuilder.group({
    NombreCompleto: ['',Validators.required ],
    Cedula: ['',Validators.required ],
    Telefono: ['',Validators.required ],
    Correo: ['',Validators.required ],
  });

  vFormProducto: FormGroup = this.formBuilder.group({
    TipoDeMadera: ['',Validators.required ],
    DescripcionADetalleDelProducto: ['',Validators.required ],
    Grueso: ['',Validators.required ],
    Ancho: ['',Validators.required ],
    Lago: ['',Validators.required ],
  });

  vFormFecha: FormGroup = this.formBuilder.group({
    FechaDeCita: ['',Validators.required ]
  });

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {

  }

  /* ------------------- Validaciones Formulario ------------------- */

  ValidarFormularioNombreCompleto(){
    if (this.vForm.get("NombreCompleto").value === ''){
      this.esValidoNombreCompleto = false;
    }
    else{
      this.esValidoNombreCompleto = true;
    } 
  }

  ValidarFormularioCedula(){
    if (this.vForm.get("Cedula").value === ''){
      this.esValidoCedula = false;
    }
    else{
      this.esValidoCedula = true;
    } 
  }

  ValidarFormularioTelefono(){
    if (this.vForm.get("Telefono").value === ''){
      this.esValidoTelefono = false;
    }
    else{
      this.esValidoTelefono = true;
    } 
  }

  ValidarFormularioCorreo(){
    if (this.vForm.get("Telefono").value === ''){
      this.esValidoCorreo = false;
    }
    else{
      this.esValidoCorreo = true;
    } 
  }

  ValidarFormularioTipoDeMadera(){
    if (this.vForm.get("Telefono").value === ''){
      this.esValidoTipoDeMadera = false;
    }
    else{
      this.esValidoTipoDeMadera = true;
    } 
  }
  

}
