

<div class="woods-fondo-app">
    <woods-navbar></woods-navbar>

    <div class="jumbotron jumbotron-fluid text-light text-center">
        <h1 class="display-2"><strong > Agende su cita </strong></h1>
    </div>

    <div class="ml-3 mt-5 mb-5 ">

        <div class="container mt-5">

            <div [ngSwitch]="numeroPagformulario">

                <div *ngSwitchCase="1">
                    <div class=" woodsForm" [formGroup]="vForm">
    
                        <div class="card custom-card-woods">
                            <div class="card-header">
                                <h4 class="text-light ">Datos personales <small>(Paso 1 de 3)</small></h4>
                                
                            </div>
                            <div class="card-body">
                            
                                <div class="form-group">
                                    <label> <i class="fa fa-user woods-icon" aria-hidden="true"></i> Nombre completo</label> 
                                    <input type="text" class="form-control" formControlName="NombreCompleto" id="NombreCompleto" placeholder="Ingrese su nombre" (input)="ValidarFormularioNombreCompleto()">
                                    <small class="text-danger" *ngIf="!esValidoNombreCompleto"> El nombre es requerido</small> 
                                </div>
                    
                                <div class="form-group">
                                    <label> <i class="fa fa-id-card-o  woods-icon" aria-hidden="true"></i> Cédula</label> 
                                    <input type="text" class="form-control" formControlName="Cedula" id="Cedula" placeholder="Ingrese su cédula" (input)="ValidarFormularioNombreCompleto()">
                                    <small class="text-danger" *ngIf="!esValidoCedula"> La cédula es requerido</small> 
                                </div>
                    
                                <div class="form-group">
                                    <label> <i class="fa fa-phone  woods-icon" aria-hidden="true"></i> Teléfono</label> 
                                    <input type="text" class="form-control" formControlName="Telefono" id="Telefono" placeholder="Ingrese su teléfono" (input)="ValidarFormularioTelefono()">
                                    <small class="text-danger" *ngIf="!esValidoTelefono"> El Teléfono es requerido</small> 
                                </div>
            
                                <div class="form-group">
                                    <label> <i class="fa fa-envelope woods-icon" aria-hidden="true"></i> Correo electrónico</label> 
                                    <input type="mail" class="form-control" formControlName="Correo" id="Correo" placeholder="correo@mail.com" (input)="ValidarFormularioTelefono()">
                                    <small class="text-danger" *ngIf="!esValidoCorreo"> El Correo electrónico es requerido</small> 
                                </div>
    
                            </div>
                        </div>
            
            
                    </div>
                </div>

                <div *ngSwitchCase="2">
                    <div class=" woodsForm" [formGroup]="vFormProducto">
                        <div class="card custom-card-woods mt-5">
                            <div class="card-header">
                                <h4>Informacion del producto <small class="text-muted">(Paso 2 de 3)</small></h4>
                                
                            </div>
                            <div class="card-body">
                                <h4>Informacion del producto</h4>
                                <hr>
                                <div class="form-group">
                                    <label>Tipo de madera</label> 
                                    <input type="text" class="form-control" formControlName="TipoDeMadera" id="TipoDeMadera" placeholder="Ingrese el tipo de madera" (input)="ValidarFormularioTelefono()">
                                    <small class="text-danger" *ngIf="!esValidoCorreo"> El tipo de madera es requerido</small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="3">
                    
                </div>

                <div *ngSwitchCase="4">
                    
                </div>
            </div>
            
                    

                    

                    
    
                </div>
        
    </div>

    <woods-footer></woods-footer>
</div>


