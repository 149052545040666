import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'woods-promociones',
  templateUrl: './woods-promociones.component.html',
  styleUrls: ['./woods-promociones.component.scss']
})
export class WoodsPromocionesComponent implements OnInit {

  textoPromocion="Promociones";
  idProductoEnPRomocion=60;
  ofertaActiva= false;

  constructor() { }

  ngOnInit(): void {
  }
  

}
