<woods-navbar></woods-navbar>

<div>
    <div class="text-center mt-5 mb-5">
        <img src="../../../assets/img/logos/WOODSCR GOLD.png" width="250" height="250" alt="Woods Costa Rica">
    </div>

    <div class="mr-5 ml-5 mt-5">
        <h3>Términos y Condiciones del Servicio</h3>
        <p class="mt-3">
            Al adquirir cualquier Producto o Servicio de nuestro sitio web <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a>
            (en adelante el “Sitio Web”) y/o utilizar o acceder a la información desplegada en el mismo, usted acepta haber estudiado y entendido todos los 
            términos y condiciones del servicio que se describen en el presente documento (en adelante “Términos y Condiciones”) tales como, pero no limitados a 
            condiciones de uso, medios para notificaciones y cláusulas de exención de responsabilidad; y manifiesta estar de acuerdo con cada uno de ellos.
        </p>

        <p>
            <strong>MADERAS DEL REVENTAZÓN SOCIEDAD ANONIMA (3-101-41590137)</strong>  (para efectos de este documento conocido como “COMERCIO”)le proporciona 
            los servicios del presente Sitio Web, sujeto a los Términos y Condiciones incluidos en el presente documento y en nuestra Política de Privacidad y 
            Consentimiento Informado. Por lo tanto, lea detenidamente estos documentos y cualquier duda que tenga en relación con los mismos puede escribirnos 
            a <a href="mailto:info@woodscostarica.com"> <label>info@woodscostarica.com</label></a>.
        </p>

        <p>
            Usted se compromete a seguir las instrucciones para la utilización correcta del Sitio y, por lo tanto, de los servicios que en el mismo se ofrecen. 
            Si usted no está de acuerdo con estos Términos y Condiciones, le rogamos no acceder ni utilizar el sitio  <a href="https://woodscostarica.com/">
            <label>https://woodscostarica.com/</label></a>, su material, su información y/o los servicios que por este medio se ofrecen. 
        </p>

        <p>
            Los Términos y Condiciones del servicio, así como nuestra Política de Privacidad y Consentimiento Informado podrán ser modificados en cualquier 
            momento en el futuro. Por esta razón, la utilización de los servicios de   <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a> un 
            momento posterior a la realización de estas modificaciones, implica que el Usuario acepta las mismas. Es obligación del Usuario estar atento a cualquier 
            cambio o modificación para estudiarlo previo a la nueva utilización del Sitio Web y/o sus servicios.
        </p>
        
        <h5>CLAUSULA PRIMERA: El operador del Sitio</h5>

        <p class="mt-3">
            El sitio web <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a> operado por la empresa <strong>MADERAS DEL REVENTAZÓN SOCIEDAD 
            ANONIMA</strong> con cédula de persona jurídica número <strong>3-101-41590137</strong>, con el correo electrónico <a href="mailto:info@woodscostarica.com"> <label>info@woodscostarica.com</label></a> con
            domicilio en Freses, San José Province, Curridabat, 11801 y también 0, Palma De Mallorca, San José Province, San Rafael de Escazú, 10203.
        </p>

        <h5>CLAUSULA SEGUNDA: El Usuario.</h5>

        <p class="mt-3">
            Para utilizar algunos de los Servicios que brinda COMERCIO a través del sitio  <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a>, 
            la  persona podrá tener que registrarse (en adelante “Usuario Registrado”). En otros casos, la persona podrá acceder a los Servicios sin estar registrada 
            (en adelante “Usuario Visitante”). Sin embargo, toda persona que desee utilizar los Servicios deberá ser mayor de edad de conformidad con la 
            legislación costarricense (18 años), deberá con capacidad legal para contratar, deberá brindar la información requerida para poder brindar los 
            Servicios y deberá manifestar el haber leído, comprendido y aceptado los Términos y Condiciones del servicio, así como nuestra Política de Privacidad y 
            Consentimiento Informado.
        </p>

        <p>
            Los Usuarios Registrados y los Usuarios Visitantes, podrán ser denominados en forma conjunta y para aquello en lo que les aplique a ambos por igual, 
            como el “Usuario”).
        </p>

        <p>
            COMERCIO requerirá, para efectos de crear la cuenta del Usuario Registrado, al menos la siguiente información: nombre, apellidos, correo electrónico, 
            teléfono, nombre de usuario bajo el cual el Usuario participará en el Sitio Web, así como cualquier otro dato que pueda ser solicitado por COMERCIO 
            para efectos de poder brindar el Servicio. El correo electrónico del Usuario será la identificación de Usuario Registrado para efectos de acceder a 
            COMERCIO. Por esta razón, no será posible cambiar el correo electrónico de la cuenta. 
        </p>

        <p>
            Los Usuarios deberán brindar información veraz y exacta y garantizan que los datos que aportan son suficientes para ser identificados y/o localizados. 
            Cualquier imprecisión que impida identificar o localizar al Usuario, así como cualquier información falsa brindada, facultará a COMERCIO para proceder 
            con la cancelación de los Servicios y la eliminación inmediata de la cuenta, en el caso de Usuarios Registrados.
        </p>

        <p>
            COMERCIO podrá proceder al cierre de la cuenta del Usuario Registrado a su sola discreción, para lo cual únicamente debería comunicar dicha decisión 
            al correo electrónico señalado por el Usuario Registrado en sus datos personales.
        </p>

        <p>
            La navegación en determinadas secciones de nuestro Sitio Web podrá o no, a la sola discreción de COMERCIO, estar condicionada al registro previo del 
            Usuario como Usuario Registrado.
        </p>

        <h5>CLAUSULA TERCERA: Sobre los Servicios</h5>

        <p>
            Los servicios que ofrece COMERCIO en su página web <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a> los siguientes 
            (para efectos de este documento los “Servicios”):
        </p>

        <ul>
            <li>
                <p>
                    Compra de Productos en Línea: Por medio del Sitio Web, el Usuario podrá adquirir Productos propios de COMERCIO o de diversos proveedores. 
                </p>
            </li>

            <li>
                <p>
                    Información: COMERCIO podrá realizar comunicaciones respecto a sus Productos y/o la tienda, sobre compras realizadas por el Usuario, así 
                    como cualquier otra comunicación o despliegue de información que COMERCIO considere pertinente, a su entera discreción. 
                </p>
            </li>
        </ul>

        <h5>CLAUSULA CUARTA: Limitaciones y exclusiones a los Servicios</h5>

        <ol>
            <li>
                <p>
                    Descripción y fotografía del producto. Las fotografías de los Productos ofrecidos en el Sitio Web exhiben los colores reales correspondientes 
                    a los Productos. Sin embargo, los colores que verá el Usuario al acceder al Sitio Web, dependerán de la calibración de su monitor. Por lo 
                    anterior no podemos garantizar que los colores mostrados en el monitor de cada Usuario sean idénticos a los del Producto. Asimismo, es 
                    fundamental que el Usuario lea detenidamente las descripciones de los Productos así como las condiciones de compra antes de realizar el 
                    pedido. Esto, con el fin de entender claramente de los términos, condiciones y restricciones que pudieren aplicar a cada Producto.
                </p>
            </li>

            <li>
                <p>
                    Existencias del Producto. El Usuario entiende y acepta que la posibilidad de concretar la venta del Producto depende del número de 
                    existencias del Producto que se mantenga en inventario. Previo a la confirmación de la transacción se informará al cliente sobre la 
                    disponibilidad de las unidades de Producto requeridas. 
                </p>
            </li>

            <li>
                <p>
                    Garantía. Nuestros Productos tienen una garantía de 30 días hábiles contados a partir de la recepción del Producto. Para efectos de
                     hacer valer la garantía, deberá seguirse el procedimiento indicado en la cláusula OCTAVA.
                </p>
            </li>

            <li>
                <p>
                    Derecho de retracto. De conformidad con el Reglamento a la Ley de Promoción de la Competencia y Defensa Efectiva del Consumidor, 
                    el consumidor tendrá un derecho de retracto que podrá hacer valer dentro de los 8 días hábiles siguientes al recibo del bien. 
                    Para efectos de hacerlo valer, deberá seguirse el procedimiento indicado en la cláusula SETIMA.
                </p>
            </li>
        </ol>

        <h5>CLAUSULA QUINTA: Precios y forma de pago</h5>

        <p class="mt-3">
            Los precios de los Productos expuestos se indican en colones, moneda de curso legal en Costa Rica, y con todos los impuestos incluidos. 
            Cualquier otro costo asociado a la compra, tal como, pero no limitado a servicios de envío, servicios de envoltura como regalo o 
            cualquier otro que pudiera ofrecer a sus Usuarios COMERCIO a su sola discreción, se cobrará por separado y se indicará en la orden 
            de compra por cancelar. 
        </p>

        <p>
            El Usuario podrá realizar el pago de sus pedidos en línea por medio sus tarjetas Visa o Mastercard y Amex. Dicho pago deberá 
            acreditarse antes del despacho del Producto. El Usuario se compromete a no realizar contra cargos en la tarjeta. 
        </p>

        <p>
            Al finalizar el proceso de compra en línea, el Usuario recibirá siempre una confirmación de la compra y una confirmación por parte del 
            procesador de pagos a su correo electrónico registrado. La factura digital de la compra, será enviada por el mismo medio. 
        </p>

        <p>
            Es fundamental, para efectos de realizar la compra, que el Usuario verifique todos los datos sobre los artículos que desea adquirir 
            para efectos de no incurrir en errores, que inserte en forma adecuada toda la información requerida para la entrega del Producto, y 
            que entienda y acepte los términos y condiciones de compra. 
        </p>

        <h5>CLAUSULA SEXTA: Envío y recepción del pedido</h5>

        <ol>
            <li>
                <p>
                    Envío y recepción del pedido. COMERCIO enviará los pedidos de Productos dentro del Territorio acordado, en un plazo máximo 
                    de cuarenta y ocho (48) horas hábiles (en adelante el “Plazo de Entrega”). El Plazo de Entrega se computará con base en el 
                    horario hábil que será de las 8:00 horas a las 17:00 horas. No se realizarán entregas feriados, salvo indicación expresa en 
                    el Sitio Web. 
                </p>

                <p>
                    Los gastos del envío, así como cualquier otro gasto relacionado con el pedido, se pagarán junto con el importe final del 
                    pedido. Los gastos de envío serán por cuenta del Usuario y se calcularán de conformidad con el destino de entrega y el 
                    número de unidades, peso y dimensiones de los Productos adquiridos. Los gastos de envío se detallarán a lo largo del proceso 
                    de compra y el comprador tendrá la posibilidad de revisar y aprobar el costo total antes de finalizar su compra.
                </p>

                <p>
                    La entrega de los pedidos se realizará en el domicilio de entrega designado libremente por el Usuario dentro del Territorio y 
                    dentro el Plazo de Entrega establecido. COMERCIO no asumirá responsabilidad alguna cuando la entrega del Producto no se 
                    pueda ejecutar como consecuencia de la inexactitud o falsedad de los datos facilitados por el Usuario, por la ausencia del 
                    destinatario, o por cualquier otra causa ajena a la responsabilidad de COMERCIO.
                </p>

                <p>
                    El Usuario será responsable por revisar el paquete en el momento en que le sea entregado. Esto con el fin de verificar que 
                    ha recibido todo el Producto por el que pagó y que el mismo se entrega en buenas condiciones. Asimismo, deberá firmar el 
                    recibido del Producto. Si por alguna razón el Producto recibido está incompleto o dañado, el Usuario no debería recibir el 
                    paquete y deberá notificar a COMERCIO sobre la situación ocurrida para que COMERCIO pueda investigar la situación y resolver 
                    el problema. El Producto deberá ser recibido por el Usuario o por una persona mayor de edad. 
                </p>

                <p>
                    Si por alguna razón fuera del control de COMERCIO el Producto no pudo llegar dentro del plazo establecido, COMERCIO procederá 
                    a hacer la reversión de la totalidad de los fondos correspondientes a el precio del artículo que no sea posible entregar, 
                    así como de cualquier otro monto adicional cobrado en relación con esa transacción, tal como pero no limitado a impuestos, 
                    transporte, etc. El Usuario acepta por este medio que se dará por satisfecho con la devolución los montos pagados y libera 
                    a COMERCIO de toda responsabilidad en relación con posibles transacciones que no haya podido ser completadas por causas 
                    ajenas a la voluntad de COMERCIO.
                </p>
            </li>

            <li>
                <p>
                    Retiro de pedidos en tiendas físicas. Los pedidos de Productos realizados a través de la tienda virtual estarán disponibles 
                    dentro de un período de veinticuatro (24) a setenta y dos (72) horas hábiles. Cuando el Producto esté listo para retirar en la 
                    tienda física, el Usuario recibirá un mensaje de texto por WhatsApp al número telefónico indicado al realizar el pedido.
                </p>
                    Para retirar el Producto en la tienda física, el Usuario deberá presentar la factura electrónica de la compra en el área de cajas 
                    de la tienda. En el caso de que el Usuario no sea quien retire el Producto personalmente, el Usuario deberá notificar a 
                    COMERCIO a través del correo electrónico <a href="mailto:info@woodscostarica.com"> <label>info@woodscostarica.com</label></a> el 
                    nombre y número de cédula de la persona que va a retirar el Producto antes de que esta se presente físicamente en la tienda. Además, 
                    la persona autorizada deberá presentar la factura electrónica de la compra en el área de cajas de la tienda para retirar el Producto.
                <p>

                </p>
            </li>
        </ol>

        <h5>CLAUSULA SETIMA: Política de devolución</h5>

        <p class="mt-3">
            El Usuario dispondrá de un plazo de ocho (7) días hábiles desde el momento de la recepción del Producto para realizar la devolución de su 
            pedido o parte del mismo, en ejercicio de su derecho de retracto.
        </p>

        <p>
            Dicha devolución se deberá realizar en forma personal en cualquiera de las sucursales físicas de la tienda COMERCIO. Para efectos de poder 
            efectuar la devolución, el Usuario deberá presentar la factura y el Producto deberá tener todas las etiquetas originales adheridas o los sellos 
            o embalaje de garantía o seguridad en los casos en que aplique. La devolución no se aceptará en aquellos casos en los que el Producto se encuentre 
            sin las etiquetas o sellos o embalaje de seguridad originales, con signos de uso, o en mal estado.
        </p>

        <p>
            El reembolso del dinero o de lo que el cliente escoja en su reemplazo se realizará a la tarjeta utilizada para realizar el pago en cuestión, en un 
            plazo máximo de 30 días hábiles. 
        </p>

        <h5>CLAUSULA OCTAVA: Procedimiento de reclamo de garantía</h5>

        <p class="mt-3">
            Cualquier reclamo de garantía deberá realizarse dentro de los 30 días hábiles siguientes a la recepción del Producto. 
        </p>

        <p>
            Para efectos de realizar el reclamo de garantía, el Usuario deberá presentarse a cualquiera de las sucursales físicas 
            de la tienda COMERCIO y deberá presentar una copia impresa de la factura junto con la prenda objeto de cambio. 
        </p>
        
        <h5>CLAUSULA NOVENA: Responsabilidad sobre la Cuenta</h5>

        <p>
            Para efectos de realizar compras en forma más ágil, así como para tener acceso de determinado contenido de acceso sólo 
            para Usuarios Registrados, el Usuario deberá registrar una cuenta, según se indicó en la cláusula SEGUNDA anterior. Dicha 
            cuenta tendrá asignado un nombre de usuario y una clave de acceso. 
        </p>

        <p>
            Todo Usuario es responsable por mantener absoluta confidencialidad sobre su nombre de usuario y clave de acceso a su cuenta 
            en COMERCIO, ya que la misma contendrá información sensible como por ejemplo pero no limitada a la tarjeta registrada para 
            efectos de pagos e información sobre compras realizadas. Todo Usuario acepta la responsabilidad por cualquier actividad ilegal 
            o no permitida de conformidad con los presentes Términos y Condiciones, que se realice mediante la utilización de su cuenta 
            registrada en COMERCIO. En este sentido el Usuario Registrado se compromete a no permitir la utilización de su cuenta por parte 
            de terceros y, en especial por parte de personas menores de edad. 
        </p>

        <p>
            El Usuario libera a COMERCIO de toda responsabilidad por daños o perjuicios ocurridos como consecuencia de la utilización de 
            la cuenta por parte de terceros. Asimismo, COMERCIO se reserva el derecho de rechazar una solicitud de servicio, cerrar una 
            cuenta o cancelar órdenes, a su sola discreción y, en especial, ante la sospecha de cualquier actividad irregular, el uso 
            de una cuenta por parte de terceros ajenos a la cuenta o por parte de menores de edad.
        </p>

        <h5>CLAUSULA DECIMA: El territorio</h5>
         
        <p class="my-3">
            Los servicios de flete y/o entrega de Productos se realizarán únicamente en el territorio de Costa Rica y en las zonas que se 
            indican en la sección, salvo que COMERCIO se comprometa a algo distinto en algún caso en particular. 
        </p>

        <p>
            Previo a la confirmación de la orden, COMERCIO le informará al Usuario sobre la disponibilidad de servicios de entrega en el 
            territorio requerido.
        </p>

        <h5>CLAUSULA DECIMA PRIMERA: Sobre los Productos a la venta</h5>

        <p class="mt-3">
            Los productos que vende COMERCIO serán todos aquellos que se indiquen en el Sitio (para efectos de este documento los “Productos”).
        </p>

        <h5>CLAUSULA DECIMA SEGUNDA: Comunicación con el Usuario</h5>

        <p>
            Todo Usuario que se registre y/o utilice los servicios de COMERCIO y/o que nos escriba por correo electrónico, recibirá comunicaciones 
            por esa misma vía (el correo electrónico registrado por el Usuario) por parte de COMERCIO. 
        </p>

        <p>
            El Usuario acepta que COMERCIO podrá comunicar por medio del correo electrónico registrado por el Usuario: información sobre 
            promociones, sobre Productos, invitaciones a eventos, notificaciones sobre cambios a los Términos y Condiciones, facturas 
            electrónicas, confirmaciones de pedidos, así como cualquier otra información o notificación formal que COMERCIO necesite 
            comunicar al Usuario. 
        </p>

        <p>
            Por lo anterior, el Usuario es responsable por mantener su información personal actualizada y por revisar periódicamente 
            su correo electrónico. 
        </p>

        <p>
            En caso de que el Usuario no desee recibir cierta información a su correo electrónico, deberá indicarlo al siguiente medio
            <a href="mailto:info@woodscostarica.com"> <label>info@woodscostarica.com</label></a>. No obstante lo anterior, es importante 
            destacar que hay información que COMERCIOnecesariamente deberá enviar al Usuario a su correo electrónico, ya sea para 
            efectos de brindar el Servicio como para poder cumplir con la legislación aplicable. Si el Usuario no autoriza a 
            COMERCIO a enviar esta información, COMERCIOno podrá brindar el Servicio.
        </p>

        <p>
            Ver Política de Privacidad completa. (<a href="#/politica-privacidad"> <label>Click aquí</label></a>)
        </p>

        <h5>CLAUSULA DECIMA TERCERA: Responsabilidad en relación con la utilización de los servicios.</h5>

        <p class="mt-3">
            Todo Usuario acepta la responsabilidad por cualquier actividad ilegal o no permitida de conformidad con los 
            presentes Términos y Condiciones, que se realice mediante la utilización de las plataformas o Servicios que provee 
            COMERCIO.
        </p>

        <p>
            El Usuario libera a COMERCIO de toda responsabilidad por daños o perjuicios ocurridos como consecuencia de la mala 
            o incorrecta utilización por parte del Usuario de las plataformas o los Servicios que provee COMERCIO a través del 
            Sitio. Asimismo, COMERCIO se reserva el derecho de rechazar una solicitud de servicio, a su sola discreción y, 
            en especial, ante la sospecha de cualquier actividad irregular o la utilización del Sitio para fines distintos a 
            los aquí previstos. 
        </p>

        <p>
            COMERCIO podrá cerrar o restringir el acceso del Usuario a los Servicios si se detecta algún uso indebido del 
            Sitio Web y/o de los servicios que se ofrecen en el mismo, entendiendo como uso indebido de manera enunciativa, 
            más no limitativa, los siguientes supuestos:
        </p>

        <ol class="mt-3">
            <li>
                <p>
                    Utilizar el Sitio Web para cualquier uso distinto de los autorizados por medio de los presentes Términos y Condiciones. 
                </p>
            </li>

            <li>
                <p>
                    La utilización de mecanismos o herramientas automatizadas o tecnología similar cuya finalidad sea realizar la 
                    extracción, obtención o recopilación, directa o indirecta, de cualquier información contenida en el Sitio Web.
                </p>
            </li>

            <li>
                <p>
                    Cualquier intento de modificación, adaptación, traducción, o conversión de los formatos o programas de cómputo 
                    del Sitio Web o a los contenidos del mismo.
                </p>
            </li>

            <li>
                <p>
                    Utilizar los códigos HTML a disposición de un tercero.
                </p>
            </li>

            <li>
                <p>
                    Copiar, imitar, replicar para su uso en servidores espejo, reproducir, distribuir, publicar, descargar, 
                    mostrar o transmitir cualquier contenido del Sitio (incluyendo marcas registradas) en cualquier forma o por 
                    cualquier medio; esta restricción incluye, pero no se limita a los siguientes medios: medios electrónicos, 
                    mecánicos, medios de fotocopiado, grabación o cualquier otro medio.
                </p>
            </li>

            <li>
                <p>
                    Acceder a datos no destinados al Usuario o iniciar sesión en un servidor o cuenta en la que el Usuario no 
                    tenga acceso autorizado.
                </p>
            </li>

            <li>
                <p>
                    Intentar interferir con el Servicio a cualquier otro Usuario, huésped o red, incluyendo, sin limitación, 
                    a través del envío de virus al Sitio, sobrecarga, inundación, spam, bombardeo de correo o fallas.
                </p>
            </li>

            <li>
                <p>
                    Enviar correo no solicitado, incluyendo promociones y/o publicidad de Productos o servicios.
                </p>
            </li>

            <li>
                <p>
                    Falsificar cualquier encabezado de TCP/IP o cualquier parte de la información del encabezado en cualquier 
                    correo electrónico o grupo de noticias.
                </p>
            </li>

            <li>
                <p>
                    Intento o realización de actividades fraudulentas entre las que se encuentran sin limitar, la falsificación 
                    de identidades.
                </p>
            </li>

        </ol>

        <p>
            Toda la información proporcionada por el Usuario deberá ser verdadera y exacta. Proporcionar cualquier información 
            falsa o inexacta constituye el incumplimiento de estos Términos y Condiciones.
        </p>

        <h5>
            CLAUSULA DECIMA CUARTA: Enlaces a otras páginas e información publicada por Usuarios o terceros 
        </h5>

        <p class="mt-3">
            COMERCIO podrá postear en su Sitio enlaces a otras páginas web. El Usuario acepta en forma expresa que COMERCIOno 
            se hace responsable por el contenido de dichos enlaces ni de la veracidad o exactitud de la información contenida 
            en dichos enlaces. 
        </p>

        <p>
            COMERCIO podría eventualmente permitir también a Usuarios o terceros postear enlaces, fotografías, comentarios, 
            ideas, valoraciones de los Servicios y/o Productos, así como cualquier otro tipo de información, siempre que la 
            misma no sea difamatoria, denigrante, injuriosa, irrespetuosa, amenazante, invasiva a la privacidad, ofensiva, 
            discriminatorios, no fundamentada, violatoria de derechos de propiedad intelectual, contentiva de virus, proselitismo, 
            “spam” o contenidos que podrían ser considerados ilegales en cualquier forma. Cualquier contenido de este tipo o que C
            OMERCIO considere falso o realizado a través de un perfil falso, podrá ser eliminado del sitio sin tener que mediar e
            xplicación alguna por parte de COMERCIO. 
        </p>

        <p>
            El Usuario y, en general, cualquier persona física o jurídica que se proponga establecer un enlace desde su sitio 
            web a <a href="https://woodscostarica.com/"><label>https://woodscostarica.com/</label></a> deberá obtener una 
            autorización previa y por escrito de COMERCIO. El establecimiento de este enlace no implica en ningún caso la 
            existencia de relaciones entre COMERCIO y el propietario o titular del sitio web en el que se establezca el mismo, 
            ni la aceptación o responsabilidad de COMERCIO en relación con sus contenidos o servicios.
        </p>

        <h5>CLAUSULA DECIMA QUINTA: Derechos de propiedad intelectual</h5>

        <p class="mt-3">
            Si un Usuario postea contenido o material en el Sitio Web, salvo indicación expresa en contrario por parte de 
            COMERCIO, el Usuario estaría otorgando a COMERCIO una autorización y/o licencia no exclusiva, gratuita y libre de 
            regalías, perpetua, irrevocable y totalmente sublicenciable para usar, reproducir, modificar, adaptar, publicar, 
            traducir, crear trabajos derivados, distribuir y exhibir dicho contenido en todo el mundo y en cualquier medio, 
            ya sea tangible o intangible. Asimismo, el Usuario autoriza a COMERCIO el derecho a utilizar el nombre, la imagen, 
            y/o cualquier contenido que el Usuario envíe, suba o postee al Sitio Web. El Usuario manifiesta y garantiza que es 
            titular o bien que cuenta con los derechos necesarios sobre el contenido que postea, que el uso del contenido que el 
            Usuario suministra no infringe ningún derecho de propiedad intelectual de terceros y no causará daños a ninguna 
            persona o entidad. El Usuario por este medio cede cualquier derecho de imagen y/o voz que aparezca en el contenido 
            que postea. Asimismo, el Usuario manifiesta que exime a COMERCIO de toda responsabilidad relacionada con el contenido 
            que postea y que indemnizará a COMERCIO por cualquier demanda resultante del contenido que el Usuario postea. 
            COMERCIO se reserva el derecho de controlar y editar o eliminar cualquier actividad o contenido que sea posteado por el
             Usuario a su entera discreción. COMERCIO no se hace responsable y no asume ninguna responsabilidad por cualquier contenido 
             posteado por el Usuario o cualquier tercero.
        </p>

        <p>
            Los derechos de propiedad intelectual sobre los contenidos del Sitio Web, incluyendo pero no limitándose a: su diseño 
            gráfico, ideas, servicios, los signos distintivos que aparecen (marcas y nombres comerciales), los programas de ordenador 
            subyacentes y/o software (incluidos los códigos fuente), así como los distintos elementos que integran el sitio web 
            (textos, gráficos, fotografías, videos, etc.) corresponden única y exclusivamente a COMERCIO.
        </p>

        <p>
            El uso del Sitio Web por parte del Usuario no implica de ninguna forma que exista una cesión de ningún derecho de propiedad 
            intelectual y/o industrial sobre el Sitio Web, sus contenidos y/o los signos distintivos de COMERCIO y/o de cualquiera de 
            las marcas que se exponen en el Sitio. A tal efecto, queda expresamente prohibido al Usuario la reproducción, transformación, 
            distribución, comunicación pública, puesta a disposición, extracción y/o reutilización del sitio web, sus contenidos y/o los 
            signos distintivos de COMERCIO y/o de cualquiera de las marcas que se exponen en el Sitio Web.
        </p>

        <h5>CLAUSULA DÉCIMA SEXTA: Liberación de responsabilidad</h5>

        <p class="mt-3">
            El Usuario reconoce y acepta que el uso del Sitio Web se realiza en todo momento bajo su entero riesgo y responsabilidad. 
            COMERCIO no será responsable por el uso indebido que pueda realizarse del Sitio Web.
        </p>

        <p>
            COMERCIO no será responsable por daños y perjuicios de cualquier tipo causados en los equipos electrónicos (cómputo, 
            teléfono, tableta, u otros) del Usuario por virus, gusanos o cualquier otro elemento dañino. El Usuario reconoce que el
             uso de la red Internet supone la asunción de un riesgo de que sus equipos informáticos puedan ser afectados por los elementos 
             antes enunciados. A tal efecto, corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección 
             y eliminación de programas electrónicos dañinos que puedan suponer un riesgo para su equipo de cómputo.
        </p>

        <p>
            COMERCIO no será responsable por problemas derivados de fallas en el sistema de la entidad bancaria por medio de la cual se pretende 
            realizar el pago, caso ante el cual el Usuario deberá comunicarse con su proveedor de servicios para corregir el problema y 
            poder efectuar la compra en cuestión.
        </p>

        <p>
            COMERCIO no tendrá responsabilidad por la falta de ejecución de sus obligaciones cuando medie fuerza mayor, caso fortuito o la entrada 
            en vigencia de legislación gubernamental, regulaciones o restricciones de cualquier otra índole que le impidan la ejecución de sus 
            obligaciones.
        </p>
        
        <h5>CLAUSULA DÉCIMA OCTAVA: Aspectos varios</h5>

        <ol>
            <li>
                <p>
                    POLITICA DE PRIVACIDAD Y CONSENTIMIENTO INFORMADO: Nuestra Política de Privacidad y Consentimiento Informado es parte de los 
                    Términos y Condiciones de uso del Sitio Web.
                </p>
            </li>

            <li>
                <p>
                    TITULOS O ENCABEZADOS: Los títulos que se emplean para encabezar cada cláusula de este documento, se introducen para facilitar 
                    su lectura, y no deben ser considerados como parte del texto del mismo para efectos de su ejecución ni para interpretar su contenido. 
                </p>
            </li>

            <li>
                <p>
                    NULIDAD: Si alguna disposición de este documento fuera declarada nula o anulable por autoridad judicial o arbitral se tendrá por no 
                    puesta, pero la legalidad y validez del resto de los Términos y Condiciones no se verá afectada o limitada por dicha omisión.
                </p>
            </li>

            <li>
                <p>
                    LEGISLACION APLICABLE Y TRIBUNALES COMPETENTES: El uso de este Sitio Web será regido e interpretado de conformidad con la 
                    legislación costarricense. Cualquier conflicto, controversia o diferencia que pudiera relacionarse con, o derivarse de la 
                    relación entre COMERCIO y el Usuario o cualquier tercero que utilice el Sitio Web será resuelto por los Tribunales de Justicia 
                    de la República de Costa Rica.
                </p>
            </li>

            <li>
                <p>
                    LIBERACIÓN DE RESPONSABILIDAD POR PRODUCTO ENTREGADO: Una vez entregado el Producto al Usuario, COMERCIO no será responsable por 
                    el uso que el Usuario le dé al Producto, ni por las consecuencias que se pudieran derivar del abuso en el consumo del mismo. 
                </p>
            </li>
        </ol>



        <p>
            Cualquier duda o consulta sobre los alcances e interpretación de los presentes Términos y Condiciones, podrá evacuarse mediante el 
            correo electrónico soporte@woodscostarica.com; la respuesta a estas consultas no implicará una modificación total o parcial de este 
            documento, o una dispensa de su cumplimiento.
        </p>

    </div>
</div>



<woods-footer></woods-footer>
