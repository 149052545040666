import { Component, OnInit, ChangeDetectionStrategy , Input } from '@angular/core';


interface Section {
  image: string;
  description: string;
  titulo: string;
  ID:number
}



@Component({
  selector: 'woods-video-slider',
  templateUrl: './woods-video-slider.component.html',
  styleUrls: ['./woods-video-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WoodsVideoSliderComponent implements OnInit {
  @Input() sections: Section[] = [];
  currentSectionIndex = 0;


  constructor() { }

  ngOnInit(): void {
  }

  onNextSection() {
    if (this.currentSectionIndex < this.sections.length - 1) {
      this.currentSectionIndex++;
    }
  }

  onPreviousSection() {
    if (this.currentSectionIndex > 0) {
      this.currentSectionIndex--;
    }
  }

}
