<div>

<section>
    <img class="principal" [src]="ImgURL" >
    
    <ul class="navigation">
        <li><img class="img-galeria"  src="../../../assets/img/carousel-galeria/img01.jpg" (click)="setIMG('../../../assets/img/carousel-galeria/img01.jpg')"></li>
        <li><img class="img-galeria" src="../../../assets/img/carousel-galeria/img02.jpeg"   (click)="setIMG('../../../assets/img/carousel-galeria/img02.jpeg')"></li>
        <li><img class="img-galeria" src="../../../assets/img/carousel-galeria/img03.jpeg"  (click)="setIMG('../../../assets/img/carousel-galeria/img03.jpeg')" ></li>
        <li><img class="img-galeria"  src="../../../assets/img/carousel-galeria/img04.jpeg" (click)="setIMG('../../../assets/img/carousel-galeria/img04.jpeg')"></li>
        <li><img class="img-galeria" src="../../../assets/img/carousel-galeria/img05.jpeg"   (click)="setIMG('../../../assets/img/carousel-galeria/img05.jpeg')"></li>
        <li><img class="img-galeria" src="../../../assets/img/carousel-galeria/img06.jpeg"  (click)="setIMG('../../../assets/img/carousel-galeria/img06.jpeg')"></li>
        <li><img class="img-galeria" src="../../../assets/img/carousel-galeria/img07.jpeg"  (click)="setIMG('../../../assets/img/carousel-galeria/img07.jpeg')"></li>

    </ul>

</section>




</div>
