import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ecopoxy-calculadora',
  templateUrl: './ecopoxy-calculadora.component.html',
  styleUrls: ['./ecopoxy-calculadora.component.scss']
})
export class EcopoxyCalculadoraComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  total = 0.0;
  Capacidad = "ml";


  vForm: FormGroup = this.formBuilder.group({
    largo: ['',Validators.required ],
    ancho: ['',Validators.required],
    profundidad: ['',Validators.required],
  })


  
  calculoResina(){
    this.total = (this.vForm.get("largo").value  * this.vForm.get("ancho").value * this.vForm.get("profundidad").value) * 1.05;
    let litros = this.total / 1000;

    if( litros>= 1.0){
      this.Capacidad = "L";
      this.total = litros;
    }
    else{
      this.Capacidad = "ml";
    }
      
    

  }

  limpiarCalculadora(){
    this.vForm.get("largo").setValue("");
    this.vForm.get("ancho").setValue("");
    this.vForm.get("profundidad").setValue("");
    this.total = 0;
  }

}
