<woods-navbar></woods-navbar>

<div class="woods-fondo-app">

    <img  class="mt-5 mb-5" src="../../../../assets/img/home/home-banner/navidadWoods.png" width="100%" alt="Woods Costa Rica">

    <woods-promociones></woods-promociones>

    <woods-carousel [URLsCarousel]="urlsImgsCarousel"></woods-carousel>
    <!--<woods-videos></woods-videos>-->  
    
    <div class=" ml-3 mt-3" *ngIf="false">
        <h3>Productos destacados</h3>

      
        <hr>
    </div>

    <div class=" ml-3 mt-5 mr-3" >
        <div class="jumbotron jumbotron-fluid text-light text-center">
            <h3 class="display-6 custom-woods-banner-title">Síguenos en instagram dando click en el siguiente enlace</h3>
            
            <a class="btn btn-woods-outline" href="https://www.instagram.com/maderasdelreventazon/" >
                <i class="fa fa-instagram fa-2x woods-icon" aria-hidden="true"></i> 
                <span class=" ml-2 align-middle">maderasdelreventazon</span>
            </a>
        </div>
    </div>

    
    

    <div class=" ml-3 mt-5 mr-3" >

        <div class="mt-3">
            <div class="row ">
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('18')">
                        <img [src]="'../../../assets/img/categorias/Cedro.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('14')">
                        <img [src]="'../../../assets/img/categorias/Cristobal.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('22')">
                        <img [src]="'../../../assets/img/categorias/Mesas.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('7')">
                        <img [src]="'../../../assets/img/categorias/Guanacaste.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('15')">
                        <img [src]="'../../../assets/img/categorias/Teca.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('1')">
                        <img [src]="'../../../assets/img/categorias/Tablilla.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('10')">
                        <img [src]="'../../../assets/img/categorias/Laurel.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('9')">
                        <img [src]="'../../../assets/img/categorias/Mora.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                    <div (click)="clickCategoria('19')">
                        <img [src]="'../../../assets/img/categorias/Cenizaro.jpg'"  class="custom-product-img-selector">
                    </div>
                </div>
            </div>

            
        </div>

        <hr>

    </div>

    <div class="mt-5 container ">
        <div class="woods-contenedor-calculadora-page">
            <div class="row">

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 woods-contenedor-calculadra"> 
                    <div class="woods-contenedor-calculadra-texto">
                        <h2>Calculadora de volumen!</h2>
                        <hr class="custon-color-texto">
                        <p>Les presentamos nuestra nueva herramienta que te ayudará en tus proyectos con resina ecopoxy.</p>
                        <a class="btn btn-woods-ecopoxi-outline" [routerLink]="['/resina-calculadora']">Ir a la calculadora</a>
                    </div>
                   
                </div>
    
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
                    <div class="woods-contenedor-calculadora-img">
                        <img [src]="'../../../assets/img/ecopoxi/calculadoraDeVolumenEcopoxy.png'"  class="custom-product-img-calciladora">
                    </div>
                    
                </div>
    
            </div>
        </div>
        
       
       
        
    </div>



    <div class="text-center mt-5 pb-5 container" >
        <div class="row text-center">
            <div class="col-4 col-sm-12 col-md-6">
                <img src="../../../../assets/img/home/home-info/descuentos.svg"  class="woods-img-info" width="75" height="75" alt="Woods Costa Rica">
                <p class="text-light mt-3">Aproveche descuentos,
                    oportunidades y ofertas</p>
            </div>

            <div class="col-4 col-sm-12 col-md-6">
                <img src="../../../../assets/img/home/home-info/maderaSeca.svg" class="woods-img-info" width="75" height="75" alt="Woods Costa Rica">
                <p class="text-light mt-3">Madera seca
                    en horno</p>
            </div>

            <div class="col-4 col-sm-12 col-md-6">
                <img src="../../../../assets/img/home/home-info/productosCalidad.svg" class="woods-img-info" width="75" height="75" alt="Woods Costa Rica">
                <p class="text-light mt-3">Productos con sello
                    de calidad certificada</p>
            </div>

            <div class="col-4 col-sm-12 col-md-6">
                <img src="../../../../assets/img/home/home-info/atencionCliente.svg" class="woods-img-info" width="75" height="75" alt="Woods Costa Rica">
                <p class="text-light mt-3">Atención personalizada
                    y asesoramiento</p>
            </div>

        </div>
        
    </div>

    <div class="text-center mt-5 pb-5 container" >
        <h3 class="text-light display-5">Somos distribuidores oficiales </h3>
        <img src="../../../../assets/img/home/home-distribuidores/exopoxy.png" width="150" height="150" alt="Woods Costa Rica">

    </div>

    



</div>

<woods-footer></woods-footer>