<link href='https://fonts.googleapis.com/css?family=Open+Sans:300,600,800' rel='stylesheet' type='text/css'>
<link href='https://fonts.googleapis.com/css?family=Martel+Sans:200,400,800' rel='stylesheet' type='text/css'>
<link href='https://fonts.googleapis.com/css?family=Biryani:200,300,400,600,800' rel='stylesheet' type='text/css'>
<link href='https://fonts.googleapis.com/css?family=Exo+2:400,100' rel='stylesheet' type='text/css'>

<!-- CSS (WebKit) -->


<div class="jumbotron jumbotron-fluid text-light text-center "  *ngIf="ofertaActiva">

    <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
           

            <a  [routerLink]="['/producto-promocion', idProductoEnPRomocion]" >
                <h3 class="animate-charcter"> {{ textoPromocion }}</h3>
                <br>
                <h5 class="animate-charcter-2">Click aqui</h5>
            </a>
            
          </div>
        </div>
      </div>
</div>


