<nav class=" woods-fondo-app ">
    <div class="text-center">
      <a class="navbar-brand pt-3" href="#">

        <img src="../../../assets/img/logos/logo-blanco.png" width="300" height="75" alt="Woods Costa Rica">

      </a>
    </div>
    <!-- Button trigger modal -->
    <button type="button" class="invisible " data-toggle="modal" data-target="#searchErrorModal" id="toggleErrorModal">
    </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item ">
        <a class="nav-link" href="#">
          <i class="fa fa-home fa-1x woods-facebook-icon" aria-hidden="true"></i>
          Home
        </a>
      </li>

      <li class="nav-item ">
        <div class="dropdown ">
          <label class=" nav-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-toggle-off fa-1x woods-icon" aria-hidden="true"></i>
            Maderas <small class="custon-woods-arror">▼</small>
          </label>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
    
            <div *ngFor="let elemento of categorias"> 
              <a class="dropdown-item" *ngIf="elemento.Estado !== 0" [routerLink]="['/categoria', elemento.ID]">{{elemento.Nombre}}</a>
            </div>
    
          </div>
        </div>


      </li>

      <li class="nav-item ">
        <div class="dropdown ">
          <label class=" nav-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-envira fa-1x woods-ecopoxi-icon" aria-hidden="true"></i>
            Resina <small class="custon-woods-arror">▼</small>
          </label>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
            
            <a class="dropdown-item" href="#/resina">Todos los productos</a>
            <a class="dropdown-item" href="#/resina-calculadora">Calculadora de volumen</a>
            
          </div>
        </div>

      </li>

      <li class="nav-item ">
        <div class="dropdown ">
          <label class=" nav-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            
            <i class="fa fa-file-image-o fa-1x woods-instagram-icon" aria-hidden="true"></i>
            Galerías <small class="custon-woods-arror">▼</small>
          </label>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
            
            <a class="dropdown-item" href="#/galeria">Galería de imagenes</a>
            <a class="dropdown-item" href="#/galeria-videos">Galería de videos</a>
            
          </div>
        </div>

      </li>


      <li class="nav-item ">
        <a class=" nav-link" href="https://api.whatsapp.com/send?phone=50683311782&text=&text=*woodscostarica.com*✅%0A%20😊👋```Saludos%2C%20acabo%20de%20ver%20tu%20sitio%20y%20quiero%20preguntar%20por:```%20&app_absent=0" target="_blank">
          <i class="fa fa-whatsapp fa-1x woods-whatsapp-icon" aria-hidden="true"></i>
          WhatsApp
        </a>
      </li>

      <li class="nav-item ">
        <a class=" nav-link" href="https://calendly.com/maderasdelreventazon/30min" target="_blank">
          <i class="fa fa-calendar fa-1x woods-mail-icon" aria-hidden="true"></i>
          Agendar cita virtual
        </a>
      </li>

    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search"  placeholder="Buscar producto..." id="search-input" >
      <button class="btn btn-woods my-2 my-sm-0" (click)="Buscar()">
        <i class="fa fa-search"></i>
      </button>
    </form>
  </div>
</nav>



<!-- Modal -->
<div class="modal fade" id="searchErrorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Alerta de Búsqueda</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        El producto buscado no está disponible
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>




