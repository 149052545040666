import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/environments/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  url = "database/"

  urlBuscar = "database/cantidadresultados/?Busqueda=";

  constructor(private http: HttpClient) { }

  obtenerCategorias(){
    return this.http.get(AppSettings.BASE_URL + this.url);
  }


  BuscarCategoria(buscar:string){
    return this.http.get(AppSettings.BASE_URL + this.urlBuscar + buscar);
  }
}


