<woods-navbar></woods-navbar>

<div class=" woods-fondo-app">
    
    <div class=" container pt-5">
        <h1 class="ml-3 ">Como usar la calculadora de volumen</h1>
        <p class="  mt-3 ml-3 mr-3">
            La calculadora de volumen se utiliza para determinar la cantidad de resina necesaria para su proyecto. Sugerimos usar un promedio para cada medición que ingrese.
        </p>
    
        <p class=" mt-3 ml-3 mr-3">
            Mida el largo, el ancho y la profundidad del área de la madera que llenará con resina , teniendo en cuenta los agujeros o espacios en la madera.
        </p>
    
        <p class=" mt-3 ml-3 mr-3">
            Si su madera es un rectángulo perfecto, ingrese sus medidas en la calculadora para obtener el estimado de volumen.
        </p>
    
        <p class=" mt-3 ml-3 mr-3">
            Si su madera es desigual, mida el espacio en múltiples intervalos y tome la medida promedio del espacio.
        </p>

    </div>
    

    <div class="contenedorParaLaCarculadora">
        <div class="mt-5 ml-3 mr-3 card card-body contenedorCalculadora woodsForm text-left" [formGroup]="vForm">
            <h3 class="text-light">Calculadora de volumen</h3>

            <hr class="custon-color">
            
            <div class="form-group">
    
                <label class="text-light">Largo</label> 
                <input type="number" class="form-control" formControlName="largo" id="largo" placeholder="cm" (change)="calculoResina()"  min="0">
                <!--<small class="text-danger" *ngIf="!esValidoNombreCompleto"> El nombre de usuario es requerido</small> -->
    
                <label class="mt-2 text-light">Ancho</label> 
                <input type="number" class="form-control" formControlName="ancho" id="ancho" placeholder="cm" (change)="calculoResina()"  min="0">
                <!--<small class="text-danger" *ngIf="!esValidoNombreCompleto"> El nombre de usuario es requerido</small> -->
    
                <label class="mt-2 text-light">Profundidad</label> 
                <input type="number" class="form-control" formControlName="profundidad" id="profundidad" placeholder="cm" (change)="calculoResina()"  min="0">
                <!--<small class="text-danger" *ngIf="!esValidoNombreCompleto"> El nombre de usuario es requerido</small> -->
    
                <div class="mt-3 row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                        <button class="btn btn-woods" (click)="limpiarCalculadora()">Limpiar</button>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                        <button class="btn btn-woods separador-btns" (click)="calculoResina()">Calcular</button>
                    </div>
    
                </div>

                <h3 class="mt-3 text-light">Total: {{ total | number : '1.2-2' }} {{ Capacidad }}</h3>
              </div>
    
        </div>
    </div>

    <div class="container mt-5">
        <h5 class="ml-3 mr-3">Llenar vacios</h5>
        <p class=" mt-3 ml-3 mr-3">
            La calculadora de volumen incluye un 5 % adicional en el volumen calculado. Este 5 % adicional es para garantizar que se alcance la profundidad de vertido tomando en cuenta cualquier margen de error y otros elementos.
        </p>
        <p class=" mt-3 ml-3 mr-3">
            La calculadora de volumen de WoodsCostaRica es una herramienta de ayuda para su proyecto, proporcionando datos aproximados, es recomendable verificar siempre las medidas.
        </p>
        <br>
    </div>

    
    
</div>

<woods-footer></woods-footer>
