<woods-navbar></woods-navbar>

<!--<video autoplay loop id="myVideo">
  <source  src="../../../../assets/video/test.mp4" type="video/mp4">
</video>-->


<div class="mb-5">
    <div class="ml-3 mt-5">
        <h1 class="display-3"><strong class=" text-muted "> Contáctenos </strong></h1>
        <hr>
        <div class=" mt-5 container">
            <div class="mt-5  woodsForm" [formGroup]="vForm">
                <div class="form-group">
                    <label>Nombre completo</label> 
                    <input type="text" class="form-control" formControlName="NombreCompleto" id="NombreCompleto" placeholder="Ingrese su nombre" (input)="ValidarFormularioNombreCompleto()">
                    <small class="text-danger" *ngIf="!esValidoNombreCompleto"> El nombre es requerido</small> 
                </div>

                <div class="form-group">
                    <label>Cédula</label> 
                    <input type="text" class="form-control" formControlName="Cedula" id="Cedula" placeholder="Ingrese su cédula" (input)="ValidarFormulariCedula()">
                    <small class="text-danger" *ngIf="!esValidoCedula"> El nombre es requerido</small> 
                </div>

                <div class="form-group">
                    <label>Teléfono</label> 
                    <input type="text" class="form-control" formControlName="Telefono" id="Telefono" placeholder="Ingrese su teléfono" (input)="ValidarFormularioTelefono()">
                    <small class="text-danger" *ngIf="!esValidoTelefono"> El nombre es requerido</small> 
                </div>

                <div class="form-group">
                    <label>Correo electrónico</label>
                    <input type="email" class="form-control" formControlName="Correo" id="Correo" placeholder="Correo electrónico" (input)="ValidarFormularioCorreo()">
                    <small class="text-danger" *ngIf="!esValidoCorreo"> El Correo electrónico es requerido</small> 
                </div>

                <div class="form-group">
                    <label>Mensaje</label>
                    <textarea class="form-control" formControlName="Mensaje" id="Mensaje" rows="3" (input)="ValidarFormularioMensaje()"></textarea>
                    <small class="text-danger" *ngIf="!esValidoMensaje"> El lugar de residencia es requerido</small> 
                </div>

            </div>

            <div class="text-rigth">
                <button class="btn btn-woods-seondary" (click)="enviarInformacion()">
                    Enviar Mensaje
                </button>

                <button type="button" class="invisible " data-toggle="modal" data-target="#sendContactModal" id="toggleSendModal">
                </button>
            </div>
        </div>
    </div>

    <div class="modal fade" id="sendContactModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Mensaje Enviado</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Su mensaje ha sido enviado
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
   


</div>

<woods-footer></woods-footer>
