import { Injectable } from '@angular/core';
import * as variable from "./sendEmail.service.js"
//import * as variable from '../../assets/smtp.js'

declare let Email: any;


declare var require: any

@Injectable({
  providedIn: 'root'
})
export class Internaldata {

  enviarInformacion( entrada:any){

    let cuerpo = `<strong> Nombre:</strong> ${entrada.NombreCompleto} \n<br>
    <strong>Cédula:</strong> ${entrada.Cedula} \r\n  <br>
    <strong>Teléfono:</strong> ${entrada.Telefono} \r\n<br>
    <strong>Correo:</strong> ${entrada.Correo} \r\n<br>
    <strong>Mensaje:</strong> ${entrada.Mensaje} \r\n<br>
   
    `
   
   
    variable.sendEmail(cuerpo);
    
  }

  

}
