import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'woods-videos',
  templateUrl: './woods-videos.component.html',
  styleUrls: ['./woods-videos.component.scss']
})
export class WoodsVideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
