import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'woods-carousel',
  templateUrl: './woods-carousel.component.html',
  styleUrls: ['./woods-carousel.component.scss']
})
export class WoodsCarouselComponent implements OnInit {

  @Input() URLsCarousel:any = []

  constructor() { }

  ngOnInit(): void {
  }

}
